import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralServices from "../../services/GeneralServices";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { showSuccessToast } from "../../utils/toastUtils";
import { getProductQuantity } from "../../utils/utility";
import TabbyPromo from "../../components/TabbyPromo";

function ProductDetails() {
  // get route parms
  const { id } = useParams();
  const { card, setCard } = useContext(AuthContext);

  const [productData, setProduct] = React.useState({});

  const [tab, setTab] = useState("details");

  const [loading, setLoading] = useState(true);

  const service = new GeneralServices();

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const res = await service.get(`product/${id}`);
      setProduct(res);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((p) => p.id === product.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((p) =>
        p.id === product.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...product, quantity: 1 }]
          : [{ ...product, quantity: 1 }],
      };
      setCard(updatedCard);
    }

    showSuccessToast("تمت اضافة المنتج");
  };

  const { product, comments } = productData;
  useEffect(() => {
    fetchProduct();
  }, [id]);

  if (loading) return <div>loading...</div>;

  return (
    <div className="container py-3">
      <div className="row">
        <div className="p-2 col-md-3 col-lg-md col-12">
          <div className=" rounded-2xl bg-white shadow">
            <img
              src={product?.image ?? "/imges/slide-4.jpg"}
              alt={product.name}
              // src="https://cdn.salla.sa/xAzvoz/3ctrpAg6jXi0cFySwOYh3XFvjVd5rM9VJMroq9kf.png"
            />
          </div>
        </div>
        <div className="p-2 col">
          <p className="text-gray-500"> {product.lable}</p>
          <p className="font-medium text-lg">{product.name}</p>
          <p className="text-gray-400">
            {/* {product.description} */}
            {!loading && product?.description?.substring(0, 100) + "..."}
          </p>
          <TabbyPromo
            status={true}
            productId={product.id}
            currency="SRA"
            price={product.price.toString()}
            installmentsCount={4}
            lang="ar"
            source="product"
            publicKey="pk_test_0190961d-8a82-b770-bfc2-87e082eed86e"
            merchantCode="SEHOOOLsau"
          />

          <div className="flex justify-between my-3">
            <h1 className="text-2xl font-bold text-rose-500">
              {product?.price} ر.س
            </h1>
            {/* {JSON.stringify(product)} */}
            {getProductQuantity(card, product.id) > 0 ? (
              <button
                className={`bg-green-500 rounded-3xl text-white px-3 py-2 flex items-center `}
              >
                <img
                  className="w-6 h-6 text-white mx-2"
                  style={{ filter: "invert(1)" }}
                  src="https://img.icons8.com/external-creatype-outline-colourcreatype/64/000000/external-bag-interface-a2-creatype-outline-colourcreatype.png"
                />
                <p>تمت الاضافة</p>
              </button>
            ) : (
              <button
                onClick={handleAddToCard}
                className={`bg-rose-500 rounded-3xl text-white px-3 py-2 flex items-center `}
              >
                <img
                  className="w-6 h-6 text-white mx-2"
                  style={{ filter: "invert(1)" }}
                  src="https://img.icons8.com/external-creatype-outline-colourcreatype/64/000000/external-bag-interface-a2-creatype-outline-colourcreatype.png"
                />
                <p>شراء</p>
              </button>
            )}
          </div>

          <div className="bg-white rounded-xl overflow-hidden shadow-lg">
            <div className="flex bg-gray-50 p-2 border-b-2">
              <div className="flex ">
                <span
                  onClick={() => setTab("details")}
                  className={`text-md cursor-pointer font-medium ${
                    tab === "details"
                      ? "text-rose-500 border-b-2 border-rose-500"
                      : "text-gray-500"
                  }`}
                >
                  التفاصيل
                </span>
                <span
                  onClick={() => setTab("comments")}
                  className={`text-md cursor-pointer font-medium mx-3 ${
                    tab === "comments"
                      ? "text-rose-500 border-b-2 border-rose-500"
                      : "text-gray-500"
                  }`}
                >
                  التعليقات
                </span>
              </div>
            </div>
            <div className="mt-2">
              {tab == "details" ? (
                <Details data={product} />
              ) : (
                <Comments product={productData} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-20" />
    </div>
  );
}

const Details = ({ data }) => {
  return <div className="p-3">{data?.description}</div>;
};

const Comments = ({ product }) => {
  const [commentsList, setCommentList] = useState([]);

  useEffect(() => {
    setCommentList(product.comments);
  }, []);

  return (
    <div>
      <p className="font-extrabold text-[24px] mx-2">التعليقات</p>

      <div className="h-1 w-full bg-gray-50 my-3" />

      {commentsList &&
        commentsList.map((comment, index) => (
          <div className="p-3 shadow-lg m-3 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  className="w-24 h-24 border-1 border-gray-300 shadow p-2 rounded-full"
                  src={` ${
                    comment?.user?.img ??
                    "https://cdn.salla.sa/xAzvoz/3ctrpAg6jXi0cFySwOYh3XFvjVd5rM9VJMroq9kf.png"
                  }`}
                />
                <div>
                  <p className="font-extrabold text-[20px] mx-2">{`${
                    comment?.user?.name
                  } ${comment?.user?.s_name ? null : ""}`}</p>
                </div>
              </div>
              <div className="mt-2">
                <p>قبل ساعه</p>
              </div>
            </div>

            <div>
              {/* اكتب تعليق جميل عن منتج اللوحم */}
              <p className="text-gray-500">{comment?.comment}</p>
              {/* <p className='text-gray-500'>{comment?.evaluation}</p> */}
            </div>
          </div>
        ))}

      <AddCOmment product={product} />
    </div>
  );
};

const AddCOmment = ({ product }) => {
  const service = new GeneralServices();

  const [comment, setComment] = useState({
    comment: "",
    evaluation: 3,
    product_id: "",
  });

  const addComment = async () => {
    setComment({ ...comment, product_id: product.id });

    try {
      const res = await service.post("/create/comment", comment);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="p-2 rounded-xl">
      <textarea
        onChange={(e) => setComment({ ...comment, comment: e.target.value })}
        className="w-full focus:border-rose-500 border-1 outline-none rounded-lg p-2"
        placeholder="اكتب تعليقك"
      ></textarea>
      <button
        onClick={addComment}
        className="bg-rose-500 rounded-3xl text-white px-3 py-2 flex items-center mt-3 hover:inset-0"
      >
        <img
          className="w-6 h-6 text-white mx-2"
          style={{ filter: "invert(1)" }}
          src="https://img.icons8.com/ios-filled/50/speech-bubble--v1.png"
        />
        <p>اضافة تعليق</p>
      </button>
    </div>
  );
};

export default ProductDetails;
