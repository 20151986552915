import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import PaymentComponent from '../../components/payments/PaymentComponent';
import MoyasarPaymentForm from '../../components/payments/MoyasarPaymentForm';
import { showSuccessToast } from '../../utils/toastUtils';
import { useNavigate } from 'react-router-dom';
import MapComponent from '../../components/MapComponent';

function AddressCreate() {


    const navigate = useNavigate();


    const [validation, setValidation] = useState([])

    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

    const [loading, setLoading] = useState(false)

    const [address, setAddress] = useState({
        name: '',
        description: '',
        geolocation: '',
        isDefault: 0,
        location: []
    })


    const riyadhDivisions = [
        'شمال الرياض',
        'جنوب الرياض',
        'شرق الرياض',
        'غرب الرياض',
    ]

    const service = new GeneralServices;


    const handleSaveAddress = async () => {
        setValidation([])
        setLoading(true)
        try {
            const res = await service.post('create/address', address)
            showSuccessToast('تم اضافة العنوان بنجاح')
            navigate('/address');

        } catch (error) {
            console.log('error', error)
            if (error?.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)
        }
    }



    useEffect(() => {
        setAddress({
            ...address,
            location: {
                "latitude": coordinates.lat,
                "longitude": coordinates.lng
            }
            ,
        })

    }, [coordinates])







    return (
        <div className='m-4 rounded shadow p-2'>
            <h1 className='text-lg font-semibold'>اسم العنوان</h1>


            <div className='mt-3 p-2 row'>

                <div className=' col sm:col-12  mx-3 rounded border p-2'>
                    <div className='row col'>
                        <div>
                            <img src='/icons/location-icon.png' />
                        </div>
                        <div class="form-group col">
                            <input onChange={(e) => setAddress({ ...address, name: e.target.value })} className='col mx-2 border-0' placeholder='قم بكتابه العنوان' />
                            <small id="helpId" class="form-text text-danger">{validation?.name?.[0]}</small>
                        </div>

                    </div>
                </div>
                <div className='row col mx-3 rounded border p-2 justify-between'>
                    <p>ضبط كعنوان افتراضي</p>
                    <div>
                        {address.isDefault == 0 ?
                            <img onClick={() => setAddress({ ...address, isDefault: 1 })} className='cursor-pointer' src='/icons/isDefaultFalse.png' />
                            :
                            <img onClick={() => setAddress({ ...address, isDefault: 0 })} className='cursor-pointer' src='/icons/isDefaultTrue.png' />
                        }
                    </div>

                </div>


            </div>
            <div className='p-3'>
                <textarea onChange={(e) => setAddress({ ...address, description: e.target.value })} className='col p-2 border' placeholder='الوصف'>
                    {address.description}
                </textarea>
                <small id="helpId" class="form-text text-danger">{validation?.description?.[0]}</small>
            </div>

            <div className='row p-3'>
                {riyadhDivisions.map((item) => (

                    <div className='col-6 p-2'>
                        <div onClick={() => setAddress({ ...address, geolocation: item })} className={`p-1 cursor-pointer  rounded border row items-center px-3`}>
                            <div className={`  ${address.geolocation === item ?
                                'w-3 h-3 bg-rose-500 rounded-full'
                                : null} `} />
                            <span >{item}</span>
                        </div>
                    </div>
                ))}
                <small id="helpId" class="form-text text-danger">{validation?.geolocation?.[0]}</small>
            </div>
            <div>
                <MapComponent setCoordinates={setCoordinates} />
            </div>
            <div className='p-3'>
                <button onClick={handleSaveAddress} disabled={loading} className='col-3 p-2 bg-rose-500 text-white rounded'>حفظ</button>
            </div>

        </div>
    )
}

export default AddressCreate