import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContextProvider';
import GeneralServices from '../../services/GeneralServices';
import Modal from '../../components/Modal';
import { showErrorToast } from '../../utils/toastUtils';

const OTP = ({ onClose }) => {




    const [otp, setOtp] = React.useState();
    const { user, setUser } = useContext(AuthContext);
    const [validation, setValidation] = useState([]);
    const [loading, setLoading] = useState(false)



    const service = new GeneralServices();


    const handleOtp = async () => {
        setValidation([])

        console.log('user', user)
        // return;
        setLoading(true)
        try {

            const res = await service.post('otp', { otp })
            localStorage.setItem('user', JSON.stringify(res))
            setUser(res)
            onClose()

        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            } else {
                showErrorToast(error.message)
                console.log('error', error)
            }
        } finally {
            setLoading(false)
        }

    }


    const resend_otp = async () => {

        setValidation([])
        setLoading(true)
        try {

            const res = await service.post('resend/otp')



        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)
        }

    }


    const logout = async () => {
        setUser(null)
        localStorage.removeItem('user')
        onClose()

    }

    return (
        <Modal onClose={onClose}>
            <div>
                <div className='justify-center items-center flex'>
                    <h1 className='text-2xl font-bold'>التحقق</h1>
                </div>
                <div className='flex col my-2'>

                    <p className='text-gray-500 font-medium'>رمز التحقق</p>
                </div>
                <div className='flex'>
                    <div className='col'>
                        <input
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className='border col p-1 rounded font-medium' />

                        <small id="helpId" class="form-text text-danger">{validation?.otp?.[0]}</small>
                    </div>


                </div>

                <div onClick={() => handleOtp()} className={`flex justify-center items-center my-2 border rounded p-2 cursor-pointer ${loading ? 'bg-gray-300' : 'bg-primaryColor'} text-white`}>
                    <span>تحقق</span>
                </div>
                <div className='flex justify-center'>
                    <p onClick={resend_otp} className='text-gray-500 font-medium items-center cursor-pointer'>اعاده ارسال</p>
                </div>
                <div className='flex justify-center'>
                    <p onClick={logout} className='text-gray-500 font-medium items-center cursor-pointer'>خروج</p>
                </div>

            </div>

        </Modal >
    );
};

export default OTP;
