import React, { useContext, useEffect, useState } from "react";
import ProductsListDesktop from "./ProductsListDesktop";
import ProductCard from "../../components/mobile/ProductCard";
import GeneralServices from "../../services/GeneralServices";
import { AuthContext } from "../../contexts/AuthContextProvider";
import useTitle from "../../hooks/useTitle";

function ProductsListMobile() {
  const [shadow, setShadow] = React.useState(false);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [activeCategory, setActiveCategory] = useState("all");

  const { setTitle } = useTitle();

  const service = new GeneralServices();

  const ToggleModel = (item) => {
    setProduct(item);
    setShadow(!shadow);
  };

  const fetchCategories = async () => {
    try {
      const res = await service.get("categories");
      setCategories(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const { data } = await service.get(`products/${activeCategory}`);
      setProducts(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //   is product added to card

  useEffect(() => {
    fetchCategories();
    setTitle("التصنيفات");
    document.title = "سهول - المنتجات";
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [activeCategory]);

  return (
    <div className="flex mt-1 h-full fixed">
      <div className="w-[90px]">
        <div className=" h-full z-30 w-[84px] p-1  overflow-x-scroll justify-center items-center scroll-snap-type scroll-snap-center">
          <div
            className={`flex flex-col rounded-lg overflow-hidden items-center text-center mt-2  ${
              activeCategory === "all" ? "border-[2px] border-red-500" : ""
            }`}
            onClick={() => setActiveCategory("all")}
            style={{ cursor: "pointer" }}
            id={`${"all"}`}
          >
            <div className={`rounded-lg w-[80px] h-[80px] overflow-hidden `}>
              <img
                src="https://static.scientificamerican.com/sciam/cache/file/101091A0-11F7-4E40-ACD4FA284C65AFB6_source.jpg?w=1200"
                className="w-[80px] h-[80px] object-cover"
              />
            </div>
            <span className=" text-[10px]"> {`الكل`}</span>
          </div>
          {categories.map((category) => (
            <div
              className={`flex flex-col rounded-lg overflow-hidden items-center text-center mt-2  ${
                activeCategory === category.id
                  ? "border-[2px] border-red-500"
                  : ""
              }`}
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              style={{ cursor: "pointer" }}
              id={`${category.id}`}
            >
              <div className={`rounded-lg w-[80px] h-[80px] overflow-hidden `}>
                <img
                  src={category.image}
                  alt={category.name}
                  // srcSet='https://static.scientificamerican.com/sciam/cache/file/101091A0-11F7-4E40-ACD4FA284C65AFB6_source.jpg?w=1200'
                  className="w-[80px] h-[80px] object-cover"
                />
              </div>
              <span className=" text-[10px]"> {category.name}</span>
            </div>
          ))}

          <div className="h-40 w-full" />
        </div>
      </div>

      <div className="flex-grow overflow-x-scroll">
        <div className="grid grid-cols-2 gap-4 px-1 mb-4">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} {...product} />
          ))}
        </div>
        <div className="h-36" />
      </div>
    </div>
  );
}

export default ProductsListMobile;
