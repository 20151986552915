import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GeneralServices from '../../services/GeneralServices'
import { showErrorToast } from '../../utils/toastUtils'

function OrdersPage() {

    const navigate = useNavigate()

    const [orders, setOrders] = useState([])

    const [orderStatus, setOrderStatus] = useState(null)


    const service = new GeneralServices;

    const fetchOrders = async (status) => {
        setOrderStatus(status)
        try {
            const { data } = await service.get('orders')
            setOrders(data)

            // if order payment_satatus == 0  add order_id in local storage the variable data contains lists of  orders 
            data.forEach(order => {
                if (order.payment_status == 0 && order.status_id == 7) {
                    localStorage.setItem('unpaidOrder', order.id)
                }
            })



        } catch (error) {
            console.log('error', error.state)
            if (error.state == 401) {
                localStorage.clear()
                showErrorToast('يجب عليك تسجيل الدخول')
                navigate('/')
            }
        }
    }

    useEffect(() => {
        fetchOrders()

        document.title = 'سهول - الطلبات'

    }, [])


    return (
        <>
            <div className='w-full justify-center items-center '>
                <img className='w-full h-40' src='imges/order-bg.png' />
                <div className='absolute top-24 space-y-4 text-white justify-center right-1/2'>
                    <div className='flex flex-col items-center'>
                        <img className='w-10 h-10' src='icons/order-icon.png' />
                        <h1 className='text-2xl'>الطلبات</h1>
                        <p>جميع الطلبات الخاصة بك</p>
                    </div>
                </div>
            </div>

            <div>
                <div className='flex my-3 items-center justify-center'>
                    <p onClick={() => fetchOrders(null)} className={`border rounded-lg cursor-pointer px-3 mx-3  p-1  ${orderStatus == null ? ' bg-red-500  text-white' : null}`}>الكل</p>
                    <p onClick={() => fetchOrders('new')} className={`border rounded-lg cursor-pointer px-3 mx-3  p-1  ${orderStatus == 'new' ? ' bg-red-500  text-white' : null}`}>جديده</p>
                    <p onClick={() => fetchOrders('done')} className={`border rounded-lg cursor-pointer px-3 mx-3  p-1  ${orderStatus == 'done' ? ' bg-red-500  text-white' : null}`}>المنتهيه</p>
                    <p onClick={() => fetchOrders('pendding')} className={`border rounded-lg cursor-pointer px-3 mx-3  p-1  ${orderStatus == 'pendding' ? ' bg-red-500  text-white' : null}`}>العلقه</p>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    {orders.length == 0 ? <NowOrder /> : orders.map((order, index) => (

                        <Order data={order} key={index} />
                    ))}

                </div>
            </div>

            <div className='h-[100px] w-full' />
        </>
    )
}



const NowOrder = () => {
    return <div className='flex w-full justify-center items-center h-80'>
        <h1 className='text-2xl'>لاتوجد طلبات</h1>
    </div>
}


const Order = ({ data }) => {


    data?.payment_data?.id == '58fcd095-89d3-426a-8d39-b2d9c087ebf5' && console.log('data', data)


    return (
        <div className='col-md-6 col-sm-12 p-3'>
            <div className=' rounded shadow m-2 p-3'>
                <div className='flex justify-between '>
                    <p>
                        <span>{data.date}</span> -
                        <span className='mx-2'>{data.time}</span>


                    </p>

                    <div>
                        {data.payment_method_id == 4 && data.payment_status == 0 && data.payment_data?.configuration?.available_products?.installments?.[0]?.web_url ? (
                            <a href={data.payment_data.configuration.available_products.installments[0].web_url} target='_blank' className={`bg-red-50 mb-2 text-red-700 px-2 rounded border-red-200`}>لم يتم الدفع</a>
                        ) : (
                            <p className={`bg-${data?.status?.color}-50 text-${data?.status?.color}-700 px-2 rounded border-${data?.status?.color}-200`}>{data?.status?.name}</p>
                        )}
                    </div>


                </div>

                <div className='flex justify-between items-end'>

                    <div className='flex items-center'>

                        <div className='w-20 h-20 bg-gray-200 rounded'></div>
                        <div className='mx-2'>
                            <p> #{data.id}</p>
                            <p>الاجمالى  : {data.total} ريال</p>
                        </div>
                    </div>
                    <Link to={`/order-details/${data.id}`} className='w-12 h-12 cursor-pointer bg-red-600 justify-center items-center flex rounded'>
                        <img src='icons/arrow-right.png' />
                    </Link>

                </div>
            </div>

        </div>
    )
}




export default OrdersPage