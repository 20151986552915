import React, { useEffect, useState } from "react";
import { AlertCircle } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

let UnpaidOrder = null;
const UnpaidOrderAlert = () => {
  const location = useLocation();

  useEffect(() => {
    UnpaidOrder = JSON.parse(localStorage.getItem("unpaidOrder")) ?? null;
  }, [location]);

  if (!UnpaidOrder) return null;
  return <div></div>;
  return (
    <Link
      to={`/order-details/${UnpaidOrder}`}
      className="bg-red-600 text-white p-4 shadow-md mb-[1px] flex items-center justify-between"
    >
      <div className="flex items-center">
        <AlertCircle className="h-5 w-5 mr-2" />
        <span className="text-sm font-semibold">يوجد طلب لم يتم دفعه.</span>
      </div>

      <p className="text-sm font-medium underline hover:text-red-200 transition-colors duration-200">
        اذهب للتفاصيل
      </p>
    </Link>
  );
};

export default UnpaidOrderAlert;
