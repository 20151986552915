import React from "react";
import { CheckCircle, Package, ShoppingBag, Printer } from "lucide-react";
import { Link, useLocation, useParams } from "react-router-dom";

const PaymentSuccessPage = () => {
  // get id form url using react-router-dom
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderId = query.get("order_id");
  const { id } = useParams();

  const orderNumber = 1234567890;
  const totalAmount = 1000;

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
        <div className="text-center">
          <div className="flex justify-center">
            <p>
              <CheckCircle className="mx-auto h-16 w-16 text-green-500" />
            </p>
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            تم تأكيد طلبك بنجاح!
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            شكراً لك على طلبك. سنرسل لك تأكيداً بالبريد الإلكتروني مع تفاصيل
            الطلب.
          </p>
        </div>

        {/* <div className="mt-8 space-y-6">
          <div className="flex justify-between items-center border-t border-b py-4">
            <span className="text-sm font-medium text-gray-500">رقم الطلب</span>
            <span className="text-sm font-bold text-gray-900">
              {orderNumber}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-500">
              إجمالي المبلغ
            </span>
            <span className="text-sm font-bold text-gray-900">
              {totalAmount} ريال
            </span>
          </div>
        </div> */}

        <div className="mt-8 space-y-4">
          <Link
            to={`/order-details/${id}`}
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Package className="h-5 w-5 mr-2" />
            تفاصيل الطلب
          </Link>

          <Link
            to="/"
            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <ShoppingBag className="h-5 w-5 mr-2" />
            متابعة التسوق
          </Link>

          <Link
            to="/print/1234567890"
            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Printer className="h-5 w-5 mr-2" />
            طباعة الإيصال
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
