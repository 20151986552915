import React, { useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import { Link } from 'react-router-dom'
import Address from './component/Address'

function AddressPage() {

    const [address, setAddress] = React.useState([])

    const service = new GeneralServices

    const get_address = async () => {
        try {
            const res = await service.get('addresses')
            setAddress(res);
        } catch (error) {
            console.log('error', error)
        }
    }

    React.useEffect(() => {
        get_address()
    }, [])


    return (
        <div>
            <div className='mb-3 flex justify-between'>
                <h1 className='font-semibold'>عنواني</h1>
                <Link to={'/address/create'} className='text-white bg-rose-500 px-2 py-1 rounded'>اضافة عنوان</Link>
            </div>
            <div className='bg-gray-100 w-full h-[1px] my-4' />
            {address && address.map((item, index) => (

                <Address setAddress={setAddress} data={item} key={item.id} />
            ))}




        </div>
    )
}

export default AddressPage