import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import { AuthContext } from "../contexts/AuthContextProvider";
import GeneralServices from "../services/GeneralServices";
import UserProfilePage from "../pages/profile/mobile/UserProfileMobile";
import useBreakpoint from "../hooks/useBreakpoints";
import Header from "../components/mobile/Header";
import BottomSheet from "../components/mobile/BottomSheet";

function ProfileLayout() {
  const isMobile = useBreakpoint();

  const { user, setUser } = useContext(AuthContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only once after initial render

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // if user is null then navigate to home page immediately
  useEffect(() => {
    setLoading(true);
    if (windowWidth <= 800) {
      if (user === null) {
        // navigate('/');
      }
    } else {
      if (user === null) {
        navigate("/login");
      }
    }

    // if windowWidth mobile

    setLoading(false);
  }, [user, isMobile]);

  if (isMobile === null || loading)
    return (
      <div className="p-2">
        <Outlet />
      </div>
    );

  return isMobile ? <Mobile /> : <DeskTop />;
}

const Mobile = () => {
  useEffect(() => {
    console.log("mobile");
  }, []);

  return (
    <div>
      {/* <Header /> */}

      <div className="p-2">
        <Outlet />
      </div>
      <Nav />
    </div>
  );
};

const DeskTop = () => {
  const { user, setUser } = useContext(AuthContext);
  useEffect(() => {
    console.log("DeskTop");
  }, []);
  // definde windowWidth

  const service = new GeneralServices();

  const handelLogout = async () => {
    try {
      const res = await service.post("logout");
      setUser(null);
      localStorage.removeItem("user");
    } catch (error) {}
  };

  return (
    <div className="wrapper">
      <Nav />

      <div className="container ">
        <div className="row">
          <div className={"col-3    "}>
            <ul className="space-y-2">
              <li>
                <Link to={"profile"} className="row items-center">
                  <img
                    className="filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1"
                    src="/icons/profile/profile.png"
                  />
                  <span className="font-bold  text-md text-black">
                    البيانات الشخصية
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/address"} className="row items-center">
                  <img
                    className="filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1"
                    src="/icons/profile/location.png"
                  />
                  <span className="font-bold  text-md text-black">عنواني</span>
                </Link>
              </li>
              <li>
                <div className="row items-center">
                  <img
                    className="filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1"
                    src="/icons/profile/coin.png"
                  />
                  <span className="font-bold  text-md text-black">
                    نقاط المحفظة
                  </span>
                  <div className="mx-7" />
                  <span className="bg-green-100 rounded-full p-1">
                    <span className=" text-green-400 mx-3 p-1 rounded ">
                      <span className="font-semibold mx-1">
                        {user?.user?.wallet}
                      </span>
                      <span>ريال</span>
                    </span>
                  </span>
                </div>
              </li>
              <li>
                <Link to="wallet" className="row items-center">
                  <img
                    className="filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1"
                    src="/icons/profile/wallet-2.png"
                  />
                  <span className="font-bold  text-md text-black">
                    شراء رصيد للمحفظة
                  </span>
                </Link>
              </li>
              {/* <li>
                        <div className='row items-center'>
                            <img className='filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1' src='/icons/profile/lang.png' />
                            <span className='font-bold  text-md text-black'>لغة التطبيق</span>
                        </div>
                    </li> */}

              <li>
                <Link to={"/about"} className="row items-center">
                  <img
                    className="filter h-[50px] w-[50px] hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1"
                    src="/icons/profile/about.png"
                  />
                  <span className="font-bold  text-md text-black">عن سهول</span>
                </Link>
              </li>
              {/* <li>
                        <div className='row items-center'>
                            <img className='filter hue-rotate-180 bg-color-[#F6F6FD] p-2 rounded mx-1' src='/icons/profile/call.png' />
                            <span className='font-bold  text-md text-black'>تحديث الرقم</span>
                        </div>
                    </li> */}
              <li>
                <div
                  onClick={handelLogout}
                  className="row items-center cursor-pointer"
                >
                  <img
                    className="bg-rose-50 border p-1 rounded mx-1"
                    src="/icons/profile/logout.png"
                  />
                  <span className="font-bold  text-md  text-rose-500">
                    تسجيل خروج
                  </span>
                </div>
              </li>
            </ul>

            {/* <div className='bg-rose-500 items-center justify-center flex text-white p-1 rounded my-4'>
                    <p>اشتراك</p>
                </div> */}

            <div className="mt-11" />
          </div>

          <div className="col">
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
const Footer = () => {
  return (
    <div className="bg-black w-full h-80 relative p-3 ">
      <div className="container py-4">
        <div className=" row">
          <div className="col-md-4">
            <h1 className="font-semibold ">بيانات التواصل</h1>

            <div className="space-y-4 mt-4">
              <div className="row">
                <img className="mx-1" src="/icons/phone.png" />
                <p>+96612352617</p>
              </div>
              <div className="row">
                <img className="mx-1" src="/icons/mail.png" />
                <p>info@COHOOLMEAT.com</p>
              </div>
              <div className="row">
                <img className="mx-1" src="/icons/location.png" />
                <p>العنوان الرياض شارع الجزار</p>
              </div>
            </div>
            <hr />
          </div>
          <div className="col-md-4 justify-center ">
            <div className="justify-center items-center flex-col flex p-2">
              <img src="imges/logo.png" />
              <p className="text-center">
                افضل امنواع الحوم المتواجد و جميع انواع اللحوم التي تحتاجة مثل
                لحم الغذال و البقر و الجمال
              </p>
            </div>
          </div>
          <div className="col-md-4 ">
            <h1 className="font-semibold ">صفحات سهول</h1>
            <div className="space-y-2">
              <ul>
                <li>
                  {" "}
                  <p className="text-opacity-70 text-gray-500 font-medium">
                    الطلبات
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-opacity-70 text-gray-500 font-medium">
                    عن سهول
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-opacity-70 text-gray-500 font-medium">
                    تواصل معنا
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-opacity-70 text-gray-500 font-medium">
                    سياسة الخصوصية
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="text-opacity-70 text-gray-500 font-medium">
                    الشروط و الأحكام{" "}
                  </p>
                </li>
              </ul>
            </div>
            <hr />
          </div>
        </div>

        <div className="row justify-between border-t pt-2 mt-3 border-t-slate-500">
          <p>2023 All Rights Reserved</p>
          <p>2023 All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
