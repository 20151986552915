import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const defaultCenter = {
    lat: 24.638916,
    lng: 46.7160104
};

function MapComponent({ setCoordinates }) {
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(defaultCenter);
    const [currentCoords, setCurrentCoords] = useState(defaultCenter);

    const onLoad = useCallback(function callback(map) {
        mapRef.current = map;
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        if (map) {
            setCoordinates(currentCoords);
        }
    }, [currentCoords, setCoordinates, map]);

    const handleCenterChanged = () => {
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            const newCoords = {
                lat: newCenter.lat(),
                lng: newCenter.lng()
            };
            if (
                newCoords.lat !== currentCoords.lat ||
                newCoords.lng !== currentCoords.lng
            ) {
                setCurrentCoords(newCoords);
            }
        }
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                const newCenter = { lat: latitude, lng: longitude };
                if (mapRef.current) {
                    mapRef.current.panTo(newCenter);
                    mapRef.current.setZoom(15);
                } else {
                    console.error("Map reference is not available");
                }
                setCenter(newCenter);
                setCurrentCoords(newCenter);
            }, (error) => {
                console.error("Error getting current location:", error);
                alert("Unable to retrieve your location. Please check your browser settings.");
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const handleZoomIn = () => {
        if (mapRef.current) {
            mapRef.current.setZoom(mapRef.current.getZoom() + 1);
        }
    };

    const handleZoomOut = () => {
        if (mapRef.current) {
            mapRef.current.setZoom(mapRef.current.getZoom() - 1);
        }
    };

    useEffect(() => {
        // Delay getting current location until the map is loaded
        if (isLoaded && mapRef.current) {
            handleGetCurrentLocation();
        }
    }, [isLoaded]);

    return isLoaded ? (
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onCenterChanged={handleCenterChanged}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '48px',
                        height: '48px',
                        transform: 'translate(-50%, -90%)'
                    }}
                >
                    <img src='https://img.icons8.com/color/48/google-maps-new.png' alt="Map marker" />
                </div>
            </GoogleMap>
            <div style={{ marginTop: '10px' }}>
                <button onClick={handleGetCurrentLocation}>
                    <img width="48" height="48" src="https://img.icons8.com/fluency/48/center-direction.png" alt="center-direction" />
                </button>
            </div>
        </div>
    ) : <></>;
}

export default React.memo(MapComponent);


// // src/MapComponent.js
// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//     width: '100%',
//     height: '400px'
// };

// const defaultCenter = {
//     lat: 24.638916,
//     lng: 46.7160104
// };

// function MapComponent({ setCoordinates }) {
//     const mapRef = useRef(null);
//     const { isLoaded } = useJsApiLoader({
//         id: 'google-map-script',
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
//     });

//     const [map, setMap] = useState(null);
//     const [center, setCenter] = useState(defaultCenter);
//     const [currentCoords, setCurrentCoords] = useState(defaultCenter);

//     const onLoad = useCallback(function callback(map) {
//         mapRef.current = map;
//         const bounds = new window.google.maps.LatLngBounds();
//         map.fitBounds(bounds);
//         setMap(map);
//     }, []);

//     const onUnmount = useCallback(function callback(map) {
//         setMap(null);
//     }, []);

//     useEffect(() => {
//         if (map) {
//             setCoordinates(currentCoords);
//         }
//     }, [currentCoords, setCoordinates, map]);

//     const handleCenterChanged = () => {
//         if (mapRef.current) {
//             const newCenter = mapRef.current.getCenter();
//             const newCoords = {
//                 lat: newCenter.lat(),
//                 lng: newCenter.lng()
//             };
//             if (
//                 newCoords.lat !== currentCoords.lat ||
//                 newCoords.lng !== currentCoords.lng
//             ) {
//                 setCurrentCoords(newCoords);
//             }
//         }
//     };

//     const handleGetCurrentLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition((position) => {
//                 const { latitude, longitude } = position.coords;
//                 const newCenter = { lat: latitude, lng: longitude };
//                 mapRef.current.panTo(newCenter);
//                 mapRef.current.setZoom(15); // Set a higher zoom level for a closer view
//                 setCenter(newCenter);
//                 setCurrentCoords(newCenter);
//             });
//         } else {
//             alert("Geolocation is not supported by this browser.");
//         }
//     };

//     const handleZoomIn = () => {
//         if (mapRef.current) {
//             mapRef.current.setZoom(mapRef.current.getZoom() + 1);
//         }
//     };

//     const handleZoomOut = () => {
//         if (mapRef.current) {
//             mapRef.current.setZoom(mapRef.current.getZoom() - 1);
//         }
//     };

//     useEffect(() => {
//         handleGetCurrentLocation();
//     }, []);


//     return isLoaded ? (
//         <div>
//             <GoogleMap
//                 mapContainerStyle={containerStyle}
//                 center={center}
//                 zoom={18}
//                 onLoad={onLoad}
//                 onUnmount={onUnmount}
//                 onCenterChanged={handleCenterChanged}
//             >
//                 <div
//                     style={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '50%',
//                         width: '48px',
//                         height: '48px',
//                         transform: 'translate(-50%, -90%)'
//                     }}
//                 >
//                     <img src='https://img.icons8.com/color/48/google-maps-new.png' />
//                 </div>
//             </GoogleMap>
//             <div style={{ marginTop: '10px' }}>
//                 <button onClick={handleGetCurrentLocation}>
//                     <img width="48" height="48" src="https://img.icons8.com/fluency/48/center-direction.png" alt="center-direction" />
//                 </button>

//             </div>
//         </div>
//     ) : <></>;
// }

// export default React.memo(MapComponent);
