import React, { useEffect, useState } from 'react'
import ProductModal from '../../components/ProductModal'
import GeneralServices from '../../services/GeneralServices'
import ProductComponent from './components/ProductComponent'
import { showSuccessToast } from '../../utils/toastUtils'

function ProductsListDesktop() {

    const [shadow, setShadow] = React.useState(false)
    const [product, setProduct] = useState({})
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    const [activeCategory, setActiveCategory] = useState(null)

    const service = new GeneralServices;


    const ToggleModel = (item) => {
        setProduct(item)
        setShadow(!shadow)
    }



    const fetchCategories = async () => {
        try {
            const res = await service.get('categories')
            setCategories(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    const fetchProducts = async () => {
        try {
            const { data } = await service.get('products')
            setProducts(data)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetchCategories()
        fetchProducts()

        document.title = 'سهول - المنتجات'
    }, [])



    return (
        <>
            <div className='relative h-[300px] '>
                <img src='/icons/products-bg.png' className='object-cover w-full h-full ' />
                <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent'></div>

                <div className='absolute bottom-3 left-1/2 transform -translate-x-1/2 p-2'>
                    <div className='flex items-center'>
                        <img src='/icons/products.png' className='align-middle w-12 ' />
                        <h1 className='text-3xl mx-3 font-extrabold text-white'>المنتجات</h1>
                    </div>
                    <p className='text-md text-white'>
                        نكهات لا تقاوم، تجربة غنية في كل قطعة

                        اكتشف عالم من النكهات الفريدة مع أفضل أنواع اللحوم الطازجة والمبردة من متجرنا.
                    </p>
                </div>
            </div>

            <div className='container py-4'>
                {shadow && <ProductModal title='تفاصيل المنتج' size='lg' data={product} onClose={ToggleModel} ></ProductModal>}


                <div className=' items-center overflow-x-scroll flex'>
                    <span className='bg-rose-500 flex  p-2 text-white rounded font-medium h-11' >الكل</span>
                    {categories && categories.map((catogery, index) => (

                        <CatogeryIcon data={catogery} setData={setProducts} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
                    ))}

                </div>

                {/* 
            <div className='row col-12'>
                {products.map((item) => (
                    <Product ToggleModel={() => ToggleModel(item)} key={item.id} data={item} />
                ))}
            </div> */}
                <div className='row col-12'>
                    {products.map((item) => (
                        <ProductComponent ToggleModel={() => ToggleModel(item)} key={item.id} data={item} />
                    ))}
                </div>
            </div>
        </>
    )
}

const CatogeryIcon = ({ data, setData, activeCategory, setActiveCategory }) => {


    const [product, setProduct] = useState({})
    const [active, setActive] = useState(false)
    const service = new GeneralServices;





    useEffect(() => {
        setProduct(data)

    }, [data])


    const fetchProducts = async () => {
        setActiveCategory(product.id)
        try {
            const { data } = await service.get(`products/${product.id}`)
            setData(data)
            showSuccessToast('تم التحديث')
        } catch (error) {
            console.log('error', error)
        }
    }

    return <div onClick={fetchProducts} className={` border cursor-pointer rounded  w-[200px] p-2 mx-1  border-rose-500 ${product.id == activeCategory ? 'bg-rose-500 text-white' : 'text-gray-500'}`} style={{ display: 'block ruby' }}>
        <div className='flex items-center w-max'>
            <img className='mx-1 w-5' src={product.image} />
            <span className=' font-medium '>{data.name}</span>
        </div>
    </div>
}





const Product = ({ col = 3, h = 80, ToggleModel, data }) => {

    return <div className={`col-md-3 col-sm-6 p-2`}>


        <div className='border  shadow rounded p-0 '>
            <div className={` bg-gray-300  h-${h} m-0`}>
                <img className='h-80 w-full' src='https://images.unsplash.com/photo-1448907503123-67254d59ca4f?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />

                <div onClick={ToggleModel} className='bg-primaryColor align-bottom w-12 h-12  rounded-full justify-center items-center flex text-white absolute top-[300px] left-3 cursor-pointer'>
                    <span className='text-[20px] font-black '>+</span>
                </div>
            </div>
            <div className='p-2 h-32'>

                <h1 className='text-lg text-medium'>{data.name}</h1>
                <div className='h-14'>

                    <p className='text-gray-500 truncate-text flex-auto'>{data.description}</p>
                </div>
                <div className='flex justify-between px-1'>
                    <h1 className='text-[20px] font-semibold'>{data.price} ريال</h1>
                    <div className=' justify-center items-center flex py-1 px-2 rounded' style={{ backgroundColor: "#f2cb04" }}>
                        <span className='font-bold mx-2  text-white'>{data.rating}</span>
                        <img className='h-4 w-4 text-white' src='/icons/star-filled.png' />
                    </div>
                </div>
            </div>
            <span className='absolute top-2 bg-white left-2 m-0 p-1 rounded-br-lg'>  7 -9 كيلو</span>

        </div>
    </div>
}




export default ProductsListDesktop