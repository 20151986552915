import React, { useEffect, useState } from 'react'
import ProductsListMobile from './ProductsListMobile';
import ProductsListDesktop from './ProductsListDesktop';
import useBreakpoint from '../../hooks/useBreakpoints';

function ProductsList() {

    const isMobile = useBreakpoint();
    if (isMobile == null) return <div>Loading</div>

    return isMobile ? <ProductsListMobile /> : <ProductsListDesktop />;
}

export default ProductsList