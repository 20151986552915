import React, { useContext, useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import { AuthContext } from '../../contexts/AuthContextProvider'


function PosScreen() {

    const [catogeries, setCatogeries] = useState([])
    const [activeCatogery, setActiveCatogery] = useState('all')

    const { user } = useContext(AuthContext)




    const [products, setProducts] = useState([])

    const service = new GeneralServices;

    const getCatogeries = async () => {
        try {
            const res = await service.get('categories')
            setCatogeries(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const fetchProducts = async () => {
        try {
            const { data } = await service.get(`products/${activeCatogery}`)
            setProducts(data)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        getCatogeries()
    }, [])

    useEffect(() => {
        fetchProducts()
    }, [activeCatogery])


    return (
        <div className='pos bg-[#f5f5f5] h-screen'>
            <div className='bg-[#ffff] '>
                <div className='p-4 flex justify-between'>
                    <h1 className='text-3xl font-bold'>نظام المبيعات</h1>

                    <div className='row'>
                        <span className='text-2xl font-medium text-orange-400'> مرحبا</span>
                        <h1 className='text-2xl mx-1 font-black text-orange-400'>{(user?.user?.name)}</h1>
                    </div>
                </div>
            </div>
            <div className='row '>
                <div className='col-9 p-4'>
                    <h1 className='text-2xl font-semibold'>الفئات</h1>

                    <div className='flex overflow-y-auto flex-row gap-2'>
                        <div className={`p-2 rounded shadow-sm max-w-max   bg-white  m-2 flex items-center cursor-pointer  ${activeCatogery === 'all' ? ' border-orange-400 border-2' : ''}`} onClick={() => setActiveCatogery('all')}>
                            <img height={40} width={40} src={`https://www.sehatbaksh.com/wp-content/uploads/2021/10/Half-goat.png`} />
                            <h1>اكل</h1>
                        </div>
                        {catogeries.map(item => (
                            <div className={`p-2 rounded shadow-sm flex max-w-max  bg-white  m-2  items-center cursor-pointer  ${activeCatogery === item.id ? ' border-orange-400 border-2' : ''}`} onClick={() => setActiveCatogery(item.id)}>
                                <img height={40} width={40} src={`https://www.sehatbaksh.com/wp-content/uploads/2021/10/Half-goat.png`} />
                                <h1>{item.name}</h1>
                            </div>

                        ))}
                    </div>

                    <div className='row h-full overflow-x-auto px-3'>
                        {products.map(item => (
                            <ProductCard data={item} />
                        ))}

                    </div>
                </div>
                <MenuCard />

            </div >
        </div>
    )
}


// menu item

const MenuCard = ({ item }) => {

    const { card, setCard } = useContext(AuthContext)

    const service = new GeneralServices;
    const handlePay = () => {

        // console.log('ahmed ', card)


        try {
            const data = fetch('https:192.168.240.1:8000/api/v1/shopping/pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(card)
            })
            console.log('data', data)
            // const res = await service.post('create_order', card)
        } catch (error) {
            console.log('error', error)
        }


    }



    return <div className='col-3 bg-white p-3 justify-between '>

        <div>
            <div className='bg-gray-50 flex  p-2 rounded '>
                <h1 className='text-2xl font-bold'>تفاصيل الطلب</h1>
            </div>

            {/* <div className='bg-orange-50 flex  p-2 rounded justify-between mt-3'>
                <h1 className='text-orange-500'>اضافة عميل </h1>
                <span className='text-orange-500 text-2xl'>+</span>
            </div> */}

        </div>

        <div className='p-2 mt-3 h-[400px] overflow-scroll'>
            {card.products.map((item, i) => (
                <div className='flex justify-between  bg-gray-50 shadow-sm px-3 mt-2 rounded items-center border-b p-1' key={i}>
                    <div className='row'>
                        {/* <h1 className='bg-gray-300  p-2 rounded text-white'>{i + 1}</h1> */}
                        <div className='flex items-center'>
                            <div className='bg-gray-300  px-2 rounded flex items-center'>
                                <h1 className=' text-white'>{item.quantity}</h1>
                            </div>
                        </div>
                        <div className='p-2'>
                            <h1 >{item.name}</h1>

                        </div>
                    </div>
                    <h1><span className='text-orange-500 font-bold text-md'>{item.price * item.quantity}</span> <span className='text-gray-500 text-sm'>ريال</span></h1>
                </div>
            ))}
        </div>

        <div>
            <div className='flex justify-between p-2'>
                <h1 className='text-2xl text-gray-400'>اجمالى</h1>
                <h1 className='text-2xl font-bold text-orange-500'>{card.total} ريال</h1>
            </div>
            <div className='flex justify-between p-2'>
                <h1 className='text-2xl text-gray-400'>الضريبه 15%</h1>
                <h1 className='text-2xl font-bold text-orange-500'>{(card.total * 0.15)} ريال</h1>
            </div>
            <div onClick={handlePay} className='flex justify-between cursor-pointer   bg-blue-500 p-3 rounded items-center'>
                <h1 className='text-2xl text-white'>{card.total + (card.total * 0.15)}</h1>
                <h1 className='text-2xl text-white'>دفع</h1>
            </div>
        </div>
    </div>
}

const ProductCard = ({ data }) => {



    const { card, setCard } = useContext(AuthContext)

    const handleIncreaseQyt = () => {
        const isProductExist = card.products && card.products.some((product) => product.id === data.id);

        if (isProductExist) {
            const updatedProducts = card.products.map((product) =>
                product.id === data.id ? { ...product, quantity: (product.quantity || 1) + 1 } : product
            );
            setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
        } else {
            const updatedCard = {
                ...card,
                products: card.products ? [...card.products, { ...data, quantity: 1 }] : [{ ...data, quantity: 1 }],
            };
            setCard(updatedCard);
        }
    };

    const handleDecreaseQyt = () => {
        const isProductExist = card.products && card.products.some((product) => product.id === data.id);
        if (isProductExist) {
            const updatedProducts = card.products.map((product) => {
                if (product.id === data.id) {
                    if (product.quantity <= 1) {
                        return null;
                    }
                    return { ...product, quantity: product.quantity - 1 };
                }
                return product;
            }).filter(product => product);
            setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
        } else {
            const updatedCard = {
                ...card,
                products: card.products ? [...card.products, { ...data, quantity: 1 }] : [{ ...data, quantity: 1 }],
            };
            setCard(updatedCard);
        }

    }



    useEffect(() => {
        // calculate total
        const t = card.products.reduce((total, product) => total + product.price * product.quantity, 0)
        // setCard card.total
        setCard({ ...card, total: t })

    }, [card.products])



    return (
        <div className="col-2 rounded overflow-hidden shadow-lg m-2 justify-center items-center text-center pt-2 h-[350px]">

            <img className="w-full h-[200px]" src={data.image} alt={data.name} />
            <div className="p-1">
                <div className="font-normal mb-2">{data.name}</div>
                <br />

                <div className='col text-center flex'>
                    <div className='col flex text-center justify-between m-1 mb-4 p-3 items-center rounded-lg bg-white' >

                        <button onClick={handleDecreaseQyt} className='w-8 h-8 font-semibold text-lg bg-rose-100 rounded text-rose-500'>-</button>
                        {/* <input className='w-8 h-8 mx-2 rounded border font-semibold text-lg text-center' value={1} /> */}
                        <button onClick={handleIncreaseQyt} className='w-8 h-8 font-semibold text-lg bg-gray-100 rounded text-gray-500'>+</button>

                    </div>
                </div>
            </div>
            <div className="p-1 absolute top-[170px]     right-2 w-full justify-between flex">
                <p className='text-orange-500 font-medium'>{data.price} ريال</p>

            </div>
        </div>
    );
};



export default PosScreen
