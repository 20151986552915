import React, { useContext, useEffect, useState } from "react";
import GeneralServices from "../../../services/GeneralServices";
import { showErrorToast } from "../../../utils/toastUtils";
import { AuthContext } from "../../../contexts/AuthContextProvider";

const Coupon = () => {
  return <CouponComp />;
  const { card, setCard } = useContext(AuthContext);

  const [code, setCode] = useState(null);

  const [couponStatus, setCouponStatus] = useState(null);

  const [couponData, setCouponData] = useState({});

  const service = new GeneralServices();

  const handleCopon = async () => {
    setCouponStatus(null);
    setCard({ ...card, copon: null });

    try {
      const res = await service.post("copon", { code });
      setCard({ ...card, copon: res });
      setCouponStatus(true);
    } catch (error) {
      showErrorToast(error?.message);
      setCouponStatus(false);
    }
  };

  useEffect(() => {
    if (card.copon) {
      setCouponStatus(true);
      setCode(card.copon.code);
    }
  }, []);

  return (
    <>
      <div
        class={`bg-white-A700 flex flex-row items-center justify-between mt-[21px] outline outline-[1px] ${
          card && card.copon !== "" && "outline-green-500"
        }  ${couponStatus == false && "outline-red-500"} ${
          couponStatus == null && " outline-gray-500"
        } p-[11px] rounded-[15px] w-full`}
      >
        {/* <p class="text-left font-tajawal lowercase mr-[5px] text-base text-gray-900_75">أدخل كود الخصم</p> */}
        <input
          className="text-base text-gray-900 w-full mx-2 rounded-lg outline-0 outline-dotted p-2"
          value={card.copon}
          onChange={(e) => setCode(e.target.value)}
          type="text"
          placeholder="أدخل كود الخصم"
        />
        <button
          onClick={handleCopon}
          className="cursor-pointer font-tajawal leading-[normal] lowercase min-w-[79px] text-base text-center rounded-lg p-2 bg-[#2F4858] text-white"
        >
          تأكيد
        </button>
      </div>
    </>
  );
};

Coupon.defaultProps = {};

export default Coupon;

// كوبون
const CouponComp = () => {
  const { card, setCard } = useContext(AuthContext);
  const [copon, setCopon] = useState({
    code: null,
    status: null,
    message: null,
  });

  const service = new GeneralServices();

  const handleCoupon = async () => {
    setCopon({
      ...copon,
      status: null,
      message: null,
    });

    setCard({
      ...card,
      coupon: null,
      discountAmount: 0,
    });
    try {
      const foundCoupon = await service.post("coupon", { code: copon.code });

      setCopon({
        ...copon,
        code: foundCoupon.code,
        status: true,
        message: null,
      });

      const discountAmount = calculateDiscountAmount(
        card.productTotal,
        foundCoupon
      );
      // Update cart state
      setCard({
        ...card,
        coupon: foundCoupon,
        discountAmount: discountAmount,
      });
    } catch (error) {
      if (error.state == 400) {
        showErrorToast(error.message);
        setCopon({
          ...copon,
          message: error.message,
        });
      } else if (error.state == 422) {
        setCopon({
          ...copon,
          message: error.validationData?.code?.[0] ?? null,
          status: false,
        });
      }
    } finally {
    }
  };

  return (
    <div className="p-4 text-[#5C6678] group rounded-lg border-dashed border-[1.8px] mt-2">
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
        <div className="flex-grow ">
          <input
            onChange={(e) => setCopon({ ...copon, code: e.target.value })}
            value={copon.code}
            placeholder="ادخل كود الخصم"
            className={`w-full h-10  flex-grow rounded-lg px-4  ${
              copon.status == false &&
              "border-red-500 border-[1.5px] text-red-600 focus:outline-none focus:ring-1 focus:ring-red-300"
            } 
                          
                          ${
                            copon.status == true &&
                            "border-green-500 border-[1.5px] text-green-600 focus:outline-none focus:ring-1 focus:ring-green-300"
                          }
                          
                          ${
                            copon.status == null &&
                            "border border-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                          }
                          `}
          />
        </div>
        <div className="w-4" />
        <div
          onClick={handleCoupon}
          className="cursor-pointer text-center  bg-[#5C6678] h-10 text-white border-dashed py-1 rounded-lg flex justify-center items-center"
        >
          <p className="px-2">تطبيق</p>
        </div>
      </div>
      <p className="text-sm text-red-500">{copon.message}</p>
    </div>
  );
};
