import * as XLSX from "xlsx";
import { Document, Packer, Paragraph, TextRun } from "docx";

export const exportToExcel = (data, fileName) => {
  // Convert data into an array of arrays (2D array)
  // const dataArray = data.map(item => Object.values(item).map(value => String(value)));
  const dataArray = data;

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Create a new worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Convert the buffer to a Blob (file object)
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL from the Blob
  const url = URL.createObjectURL(blob);

  // Create a temporary link and click it to trigger the download
  const a = document.createElement("a");
  a.href = url;
  const date =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate() +
    "_" +
    new Date().getMilliseconds();
  a.download = date + `_${fileName}.xlsx`;
  a.click();

  // Release the URL object
  URL.revokeObjectURL(url);
};

export const exportToWord = (data, fileName, options) => {
  // Create a new document
  const doc = new Document();

  // Add a title to the document
  const title = new Paragraph().addRun(new TextRun("Suppliers List").bold());
  doc.addParagraph(title);

  // Add supplier details to the document
  data.forEach((supplier) => {
    const supplierDetails = new Paragraph().addRun(
      new TextRun(
        `Name: ${supplier.name}\nAddress: ${supplier.address}\nContact: ${supplier.contact}\n\n`
      )
    );
    doc.addParagraph(supplierDetails);
  });

  // Generate the Word document
  const packer = new Packer();
  packer.toBlob(doc).then((blob) => {
    // Create a URL from the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link and click it to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.docx`;
    a.click();

    // Release the URL object
    URL.revokeObjectURL(url);
  });
};

export const client_name = (client) => {
  if (client?.type == "individual") {
    return client?.data?.first_name + " " + client?.data?.last_name;
  } else if (client?.type == "private") {
    return client?.data?.name;
  } else if (client?.type == "government") {
    return client?.data?.name;
  }
};
export const client_phone = (client) => {
  return client?.data?.phone;
};
export const elevator_type = (contract) => {
  return contract?.elevatorType?.name;
};

export const client_type = (client) => {
  if (client?.type == "individual") {
    return "Individual";
  } else if (client?.type == "private") {
    return "Private";
  } else if (client?.type == "government") {
    return "Government";
  } else {
    return "undefined";
  }
};

export const contract_status = (contract) => {
  let statusClass = "";
  let statusText = "";

  switch (contract.contract_status) {
    case "Draft":
      statusClass = "text-warning bg-yellow-500 p-2";
      statusText = "Draft";
      break;
    case "Pending":
      statusClass = "text-warning bg-yellow-500 p-2";
      statusText = "Pending";
      break;
    case "assigned":
      statusClass = "text-warning bg-yellow-500 p-2";
      statusText = "Assigned";
    default:
      statusText = contract.contract_status;
      break;
  }

  return statusClass;
};
export const playSound = async (soundPath) => {
  try {
    const sound = new Audio();
    sound.src = soundPath;

    sound.addEventListener("error", (error) => {
      console.error("Error loading sound:", error);
    });

    await sound.play();
  } catch (error) {
    console.error("Failed to play sound:", error);
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get the day, month, and year components of the date
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 since months are zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

// Utility function to format date and time
export const formatDateAndTime = (dateString) => {
  const dateTime = new Date(dateString);

  // Format the date and time
  const formattedDate = dateTime.toLocaleDateString();
  const formattedTime = dateTime.toLocaleTimeString();

  return { formattedDate, formattedTime };
};

// formatCurrency
export const formatCurrency = (amount, currencyCode = "SAR") => {
  return new Intl.NumberFormat("en-SA", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

// Utility function to calculate totals
export const calculateTotals = (
  products,
  options,
  discountAmount,
  deliveryType,
  coupon
) => {
  const productTotal = products.reduce((acc, product) => {
    return acc + product.price * product.quantity;
  }, 0);

  if (coupon && coupon !== null) {
    discountAmount = calculateDiscountAmount(productTotal, coupon);
  }
  const optionsTotal = options.reduce((acc, option) => {
    return acc + option.price;
  }, 0);

  const deliveryCost = deliveryType ? deliveryType.price : 0;
  const total = productTotal + optionsTotal + deliveryCost - discountAmount;
  const tax = Math.round(total * 0.15 * 100) / 100;
  const grandTotal = Math.round((total + tax) * 100) / 100;
  const discountAmountRounded = Math.round(discountAmount * 100) / 100;

  return {
    productTotal,
    optionsTotal,
    total,
    tax,
    grandTotal,
    discountAmountRounded,
  };
};

export const calculateDiscountAmount = (productTotal, coupon) => {
  let discountAmount = 0;

  if (coupon) {
    if (coupon.type === "fixed") {
      discountAmount = coupon.value;
    } else if (coupon.type === "percentage") {
      discountAmount = (productTotal * coupon.value) / 100;
    }
  }

  return discountAmount;
};

export const resetCard = () => ({
  products: [],
  options: [],
  idDelivery: false,
  deliveryTime: null,
  deliveryDate: null,
  paymentMethod: null,
  deliveryType: null,
  coupon: null,
  optionsTotal: 0,
  deliveryCost: 0,
  productTotal: 0,
  discountAmount: 0,
  total: 0,
  tax: 0,
  grandTotal: 0,
  auth: "",
});

export const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

/**
 * Get the quantity of a specific product in the cart
 * @param {Object} cart - The cart object containing products
 * @param {number|string} productId - The ID of the product to check
 * @returns {number} The quantity of the product in the cart, or 0 if not found
 */
export const getProductQuantity = (cart, productId) => {
  if (!cart || !cart.products || !Array.isArray(cart.products)) {
    return 0;
  }

  const product = cart.products.find((item) => item.id === productId);
  return product ? product.quantity : 0;
};
