import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import "./App.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import AuthContextProvider, {
  AuthContext,
} from "./contexts/AuthContextProvider";
import { Toaster } from "react-hot-toast";
import runOneSignal from "./utils/onesignal";

const App = () => {
  const { user } = JSON.parse(localStorage.getItem("user")) ?? [];

  // const beamsClient = new PusherPushNotifications.Client({
  //   instanceId: "608af209-9cef-40c2-88dc-a42349540956",
  // });

  useEffect(() => {
    runOneSignal();
  });
  useEffect(() => {
    // if (user?.email) {
    //   beamsClient
    //     .start()
    //     .then((beamsClient) => beamsClient.getDeviceId())
    //     .then((deviceId) =>
    //       console.log("Successfully registered with Beams. Device ID:", deviceId)
    //     )
    //     .then(() => beamsClient.addDeviceInterest(user.email))
    //     .then(() => beamsClient.getDeviceInterests())
    //     .then((interests) => console.log("Current interests:", interests))
    //     .catch(console.error)
    // } else {
    //   console.log('user', user)
    // }
  }, []);

  // const [message, setMessage] = useState(null);

  // useEffect(() => {
  //   OneSignal.onNotificationReceived(event => {
  //     console.log("Notification received:", event.toJS());
  //     setMessage(event.notification.payload.body);
  //   });

  //   return () => {
  //     OneSignal.offNotificationReceived();
  //   };
  // }, []);

  // OneSignal.getUserId((_userId) => {
  //   // setUserId(_userId);
  //   console.log('_userId', _userId)
  // });

  // // (Optional) Request permission for notifications
  // OneSignal.promptForNotifications();
  // }, []);

  // return <div>
  //   {message && (
  //     <p>Notification: {message}</p>
  //   )}
  // </div>
  // return <PaymentForm />
  // return <RainfallAnalysisDashboard />

  // return <ProductPage />

  // return <ResidenceCertificate />;
  return (
    <AuthContextProvider>
      <Toaster reverseOrder={false} />
      <Router>
        <AppRoutes />
      </Router>
    </AuthContextProvider>
  );
};

export default App;
