import React, { useState } from 'react'
import GeneralServices from '../../../services/GeneralServices';


const Address = ({ data, setAddress }) => {

    const [loading, setLoading] = useState(false)

    const service = new GeneralServices;

    const handleDeleted = async () => {
        try {
            const res = await service.post(`/address/delete/${data.id}`)
            setAddress(res)

        } catch (error) {

        }
    }


    const makeItDefault = async () => {
        try {
            const res = await service.post(`/address/default/${data.id}`)
            setAddress(res)
            console.log('res', res)
        } catch (error) {

        }
    }

    return <div className='row my-5'>
        <img src='/icons/map.png' />
        <div className='mx-2 col'>
            <h1 className='font-semibold'>{data.name}</h1>
            <p className='font-light text-stone-300'>{data.description}</p>
        </div>
        <div className='px-3 flex flex-col'>
            <div className='row space-x-2'>
                {/* <img className='mx-1 cursor-pointer' src='icons/edit.png' /> */}
                <img className='mx-1 cursor-pointer' onClick={handleDeleted} src='icons/delete.png' />
            </div>
            <div className='flex-col flex-1' />
            {data.isDefault == 0 ?
                <button onClick={() => makeItDefault()} className='bg-rose-100 w-28 text-rose-500 p-1 rounded'>جلعه افتراضى</button>
                : <button className='bg-rose-500 text-white  w-28 p-1 rounded'>افتراضى</button>
            }

        </div>
    </div>
}


export default Address