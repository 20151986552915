import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContextProvider";
import Modal from "../../components/Modal";
import InputFormGroup from "../../components/Form/InputFormGroup";
import GeneralServices from "../../services/GeneralServices";
import Switch from "../../components/Switch";
import BottomSheet from "../../components/mobile/BottomSheet";
import Input from "../../components/Input";
import Button from "../../components/Button";
import CardInfo from "./components/CardInfo";

const PaymentMethods = ({ validation }) => {
  const { user, card, setCard } = useContext(AuthContext);

  const [showPayment, setShowPayment] = useState(false);
  const [source, setSource] = useState({});

  //   setIsOpen
  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState([]);

  const handleChangePayment = (payment) => {
    if (payment == 2) setIsOpen(true);
    setCard({ ...card, paymentMethod: payment });

    // if (payment == "creditcard") {
    //   // setCard({ ...card, paymentMethod: payment })
    //   onClose();
    // } else {
    // }
  };

  // مالجه الدفع عن طريق الفيزا
  const handleCreadetPayment = (key, value) => {
    setCard({
      ...card,
      paymentMethod: "creditcard",
      creditcard: { ...card.creditcard, [key]: value },
    });
  };

  //   const onClose = () => setShowPayment(!showPayment);

  const conform = () => {
    setError([]);

    // validation card creditcard name required
    if (
      card?.creditcard?.fullname == null ||
      card?.creditcard?.fullname == ""
    ) {
      setError({
        name: "الاسم اجبارى",
      });
      return;
    } else if (
      !card?.creditcard?.number ||
      card?.creditcard?.number == null ||
      card?.creditcard?.number == ""
    ) {
      setError({
        number: "الرقم اجبارى",
      });
      return;
    } else if (
      !card?.creditcard?.cvv ||
      card?.creditcard?.cvv == null ||
      card?.creditcard?.cvv == ""
    ) {
      setError({
        cvv: "الرقم السري اجبارى",
      });
    } else if (
      !card?.creditcard?.year ||
      card?.creditcard?.year == null ||
      card?.creditcard?.year == ""
    ) {
      setError({
        year: " السنه اجبارى",
      });
    } else if (
      !card?.creditcard?.month ||
      card?.creditcard?.month == null ||
      card?.creditcard?.month == ""
    ) {
      setError({
        month: "الشهر اجبارى",
      });
    } else {
      setCard({ ...card, paymentMethod: "creditcard" });
      setShowPayment(!showPayment);
    }
  };

  const [paymentOptionsOptions, setPaymentOptionsOptions] = useState([]);

  const service = new GeneralServices();

  const fetchPaymentOptions = async () => {
    try {
      const res = await service.get("payment/methods");
      setPaymentOptionsOptions(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  //   onClose
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    fetchPaymentOptions();
  }, []);

  return (
    <div className="my-1">
      {showPayment && (
        <Modal onClose={onClose} title="بيانات الدفع">
          <div className="space-y-2">
            {/* <div class="form-group col">
                        <label for="type">نوع الدفع</label>
                        <select class="form-control" name="type" id="type">

                            <option>اختر طريقه الدفع</option>
                            <option>اختر طريقه الدفع</option>
                            <option>اختر طريقه الدفع</option>
                        </select>
                    </div> */}

            <InputFormGroup
              placeholder={`الاسم`}
              value={card?.creditcard?.fullname}
              onChange={(e) => handleCreadetPayment("fullname", e.target.value)}
              col={12}
              key={"fullname"}
              error={error?.fullname}
            />

            <InputFormGroup
              placeholder={`الرقم`}
              value={card?.creditcard?.number}
              onChange={(e) => handleCreadetPayment("number", e.target.value)}
              col={12}
              key={"number"}
              error={error?.number}
            />

            {/* <div className=' justify-between'> */}

            <InputFormGroup
              placeholder={`CVC/CVV`}
              value={card?.creditcard?.cvv}
              onChange={(e) => handleCreadetPayment("cvv", e.target.value)}
              key="cvc"
              error={error?.cvv}
              col={12}
            />

            <InputFormGroup
              label={"السنه"}
              placeholder={`26`}
              value={card?.creditcard?.year}
              onChange={(e) => handleCreadetPayment("year", e.target.value)}
              type="text"
              key={"year"}
              error={error?.year}
              col={12}
            />
            <InputFormGroup
              label={"الشهر"}
              placeholder={`04`}
              value={card?.creditcard?.month}
              onChange={(e) => handleCreadetPayment("month", e.target.value)}
              type="text"
              key={"month"}
              error={error?.month}
              col={12}
            />
            <button onClick={conform} className=" rounded p-1 btn-primary">
              تاكيد
            </button>
            <button onClick={onClose} className=" rounded p-1 bg-red mx-2">
              اغلاق
            </button>

            {/* </div> */}
          </div>
        </Modal>
      )}

      <BottomSheet isOpen={isOpen} onClose={onClose} title="طرق الدفع">
        <CardInfo
          card={card}
          setCard={setCard}
          //   validation={validation}
          onClose={onClose}
        />
      </BottomSheet>

      <div className="shadow p-3 rounded-2xl">
        <h1 className="font-semibold">طرق الدفع</h1>
        <hr className="my-3 border-dashed" />
        <small className="text-red">{validation?.paymentMethod?.[0]}</small>

        {paymentOptionsOptions?.map((item, index) => {
          if (item.id === 1 && card.deliveryType && card.deliveryType.id == 6)
            return;
          if (item.id === 1 && card.deliveryType && card.deliveryType.id == 5)
            return;
          return (
            <div className="flex gap-3">
              <Switch
                checked={card?.paymentMethod === item.id}
                onChange={() => handleChangePayment(item.id)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p>{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CardInfos = ({ card, setCard, validation, onClose }) => {
  return (
    <>
      <Input
        value={card?.source?.name}
        onChange={(e) =>
          setCard({
            ...card,
            source: { ...card.source, name: e.target.value },
          })
        }
        placeholder={"اسم حامل البطاقه"}
      />
      <Input
        value={card?.source?.number}
        onChange={(e) =>
          setCard({
            ...card,
            source: { ...card.source, number: e.target.value },
          })
        }
        placeholder={"اكتب رقم البطاقة"}
      />
      <div className="flex justify-between">
        <div className="w-[29%]">
          <Input
            value={card?.source?.cvc}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, cvc: e.target.value },
              })
            }
            placeholder={"cvc"}
          />
        </div>
        <div className="w-[29%]">
          <Input
            value={card?.source?.month}
            placeholder={"الشهر"}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, month: e.target.value },
              })
            }
          />
        </div>
        <div className="w-[29%]">
          <Input
            value={card?.source?.year}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, year: e.target.value },
              })
            }
            type="number"
            placeholder={"السنه"}
          />
        </div>
      </div>
      <Button onClick={onClose} text={"تأكيد"} />
    </>
  );
};

export default PaymentMethods;
