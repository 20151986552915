import React, { useEffect, useState } from "react";
import { Children } from "react";
import { Link, useParams } from "react-router-dom";
import GeneralServices from "../../services/GeneralServices";
import Modal from "../../components/Modal";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import useTitle from "../../hooks/useTitle";
import {
  Cancel,
  Clock,
  Archive,
  Circle,
  TruckIcon,
  Package2,
  CheckCircle2,
  Check,
  CircleAlertIcon,
  CreditCard,
  CreditCardIcon,
} from "lucide-react";
import Button from "../../components/Button";

function OrderDetails() {
  const { id } = useParams();

  const [order, setOrder] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [ratingType, setRatingType] = useState("order");
  const [ratingData, setRatingData] = useState({});

  const { setTitle } = useTitle();

  const service = new GeneralServices();

  const fecthOrder = async () => {
    try {
      const { data } = await service.get(`order/${id}`);

      setOrder(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fecthOrder();
    setTitle("تفاصيل الطلب #" + id);
  }, []);

  const convertToFormattedTime = (dateString) => {
    const dateObject = new Date(dateString);

    return dateObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const statusIcons = {
    "قيد المعالجة": <Clock className="w-6 h-6 text-green-500" />,
    "تم استلام الطلب": <CheckCircle2 className="w-6 h-6 text-green-500" />,
    "جاري تجهيز الطلب": <Package2 className="w-6 h-6 text-green-500" />,
    "جاري التوصيل": <TruckIcon className="w-6 h-6 text-green-500" />,
    "تم تسليم الطلب": <Archive className="w-6 h-6 text-green-500" />,
    ملغي: <Circle className="w-6 h-6 text-red-500" />,
  };

  const [rating, setRating] = useState(0);

  const openModel = (type) => {
    setRatingType(type);

    setRatingData({
      ...ratingData,
      type: type,
      stars: 0,
      comment: "",
      order_id: id,
    });
    setShowModel(true);
  };

  const closeModel = () => setShowModel(false);

  const handleCansel = async () => {
    try {
      const { data } = await service.post(`order/cancel/${id}`, {
        message: "تم الغاء الطلب",
      });

      setOrder(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="container bg-color-[##fbfafc]">
      {showModel && order && (
        <Modal onClose={() => closeModel()}>
          <Rating
            closeModel={closeModel}
            setRatingData={setRatingData}
            ratingData={ratingData}
          />
        </Modal>
      )}

      <div className="row">
        <div className="col-md-6 p-2 col-sm-12">
          <div className="col bg-white rounde shadow h-full p-2">
            <div className="flex justify-between p-3">
              {/* <h1 onClick={fecthOrder} className='cursor-pointer bg-green-200 text-green-600 px-2 rounded-full items-center block'>تحديث</h1> */}
              <h1 className="cursor-pointer text-gray-600 font-semibold ">
                حالات الطلب
              </h1>
              <Link to={`/print/${id}`}>
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios/50/print--v1.png"
                  alt="print--v1"
                />
              </Link>
            </div>

            {order?.logs &&
              order.logs.map((log, index) => (
                <div key={index}>
                  <div className="flex py-2 justify-between items-center">
                    <div className="flex items-center">
                      <div
                        className={`w-16 h-16 rounded-full flex items-center justify-center bg-gray-100`}
                      >
                        {statusIcons[log?.status?.name]}
                      </div>
                      <p className="mx-2 font-medium">{log?.status?.name}</p>
                    </div>
                    <p className="mx-2 text-gray-500">
                      {convertToFormattedTime(log.created_at)}
                    </p>
                  </div>
                  {index < order.logs.length - 1 && (
                    <div className="px-4" style={{ marginRight: "4px" }}>
                      <div className="w-1 h-6 rounded-full bg-gray-300"></div>
                    </div>
                  )}
                </div>
              ))}

            {/* {order?.status && order?.status_id == 2 && (

                            <>
                                <div className='justify-between flex px-4 mt-5'>
                                    <button onClick={() => openModel('order')} className='p-2 col-3 text-center flex items-center justify-center text-white bg-red-500 rounded'>قيم الطلب</button>
                                    <button onClick={() => openModel('user')} className='p-2 col-3 text-center flex items-center justify-center text-white bg-red-500 rounded'>قيم السائق</button>
                                </div>
                            </>
                        )}


                        {order?.status && order?.status_id == 1 && (
                            <div className='justify-between flex px-4 mt-5'>
                                <button onClick={handleCansel} className='p-2 col text-center flex items-center justify-center text-white bg-red-700 text-base rounded'>الغاء الطلب</button>
                            </div>
                        )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-12 bg-white h-full">
          <div className="p-3 justify-between flex  my-3 bg-white rounded shadow">
            <p> #{order.id} الطلب رقم</p>
            <p>{order.date}</p>
          </div>

          <div className="bg-white my-3 p-2 rounded shadow">
            <h2 className="font-semibold text-md">عنوان التوصيل</h2>
            <div className="flex items-center my-2">
              <div className="rounded h-28 w-28 bg-gray-300"></div>
              <div className="mx-2">
                <h1 className="font-bold text-2xl">{order?.address?.name}</h1>
                <p className="text-gray-400">{order?.address?.description}</p>
              </div>
            </div>
          </div>
          {order?.delivery_boy && (
            <div className="bg-white my-3 p-2 rounded shadow">
              <h2 className="font-semibold text-md">مندوب التوصيل</h2>
              <div className="flex items-center my-2">
                <div className="rounded h-28 w-28 bg-gray-300 justify-center items-center flex">
                  <img
                    className="w-28 h-28"
                    src={order?.delivery_boy?.img}
                    alt={order?.delivery_boy?.name}
                  />
                </div>
                <div className="mx-2">
                  <h1 className="font-bold text-2xl">{` ${order?.delivery_boy?.name} ${order?.delivery_boy?.s_name}`}</h1>
                  <p className="text-gray-400">{order?.delivery_boy?.phone}</p>
                </div>
              </div>
            </div>
          )}
          <div className="bg-white my-3 p-2 rounded shadow">
            <h2 className="font-semibold text-md">ملخص الطلب</h2>
            {order?.items &&
              order?.items.map((product, index) => (
                <div className="flex items-center my-2 justify-between p-3">
                  <p className="font-light text-gray-500">
                    <span>{product.product} </span>
                    <span>x{product.quantity} </span>
                    التقيطع :<span>{product.CuttingType} </span>
                  </p>
                  {/* <h2 className='font-medium text-md'>    {product.total} ريال</h2> */}
                </div>
              ))}

            <div className="w-full border" />

            {/* <div className='flex items-center my-1 justify-between p-3'>
                            <p className='font-semibold text-gray-500'> رسوم التوصيل</p>
                            <h2 className='font-medium text-md'>    {order.delivery_price} ريال</h2>
                        </div> */}
            {/* <div className='flex items-center my-1 justify-between p-3'>
                            <p className='font-semibold text-gray-500'>  الضريبه 15%</p>
                            <h2 className='font-medium text-md'>    {parseFloat(order.total * 0.15).toFixed(2)} ريال</h2>
                        </div> */}
            {/* <div className='flex items-center my-1 justify-between p-3'>
                            <p className='font-semibold text-gray-500'>  المجموع الفرعي</p>
                            <h2 className='font-medium text-md'>    {order.total - order.delivery_price - parseFloat(order.total * 0.15).toFixed(2)} ريال</h2>
                        </div> */}
            {/* <div className='flex items-center my-1 justify-between p-3'>
                            <p className='font-semibold text-gray-500'> خصم</p>
                            <h2 className='font-medium text-md'>    450 ريال</h2>
                        </div> */}

            <div className="w-full border" />

            <div className="flex items-center my-1 justify-between p-3">
              <p className="font-semibold text-black"> المجموع الكلي</p>
              <h2 className="font-black text-rose-500 text-lg">
                {" "}
                {order.total} ريال
              </h2>
            </div>
            {order.payment_status == 0 && (
              <a
                href={
                  order.payment_data.configuration.available_products
                    .installments[0].web_url
                }
                target="_blank"
                className={`bg-red-50 mb-2 text-red-700 px-2 rounded border-red-200`}
              >
                <Button
                  leftIcon={<CreditCardIcon size={20} />}
                  className="w-full mt-3"
                  bg="green"
                  text={"اكمل الدفع"}
                />
              </a>
            )}
            {order.status_id == 1 ||
              (order.status_id == 7 && (
                <Button
                  leftIcon={<CircleAlertIcon size={20} />}
                  className="w-full mt-3"
                  onClick={() => handleCansel()}
                  text={"الغاء الطلب"}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="h-[100px] w-full " />
    </div>
  );
}

const Rating = ({ ratingData, setRatingData, closeModel }) => {
  const service = new GeneralServices();

  const dispatRating = async () => {
    console.log("ratingData", ratingData);

    try {
      const res = await service.post("rating", ratingData);
      showSuccessToast("تم تقييم الطلب بنجاح");
      closeModel();
    } catch (error) {
      showErrorToast(error?.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {/* Image (assuming you have an image URL stored in a variable) */}
      <img
        className=" h-32 w-32 rounded-full object-cover"
        src="/imges/rating.webp" // Replace with your image URL
        alt="Driver Image"
      />
      <p className="text-2xl font-bold">
        {ratingData?.type == "order" ? "قيم الطلب" : "تقيم سائق"}
      </p>

      <div className="flex flex-row space-x-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`cursor-pointer text-yellow-500 hover:text-yellow-700 ${
              ratingData.stars >= star ? "text-yellow-700" : ""
            }`}
            onClick={() => setRatingData({ ...ratingData, stars: star })}
          >
            <img
              alt="Star"
              className="w-6 h-6"
              src="https://img.icons8.com/ios/50/star--v1.png"
            />
          </span>
        ))}
      </div>

      <textarea
        className="w-full border border-blue-500 focus:border-sky-500 rounded-md p-2"
        onChange={(e) =>
          setRatingData({ ...ratingData, comment: e.target.value })
        }
        placeholder="اكتب تعليقك هنا" // Placeholder in Arabic
      />

      <button
        onClick={dispatRating}
        className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none font-medium"
      >
        قيم
      </button>
    </div>
  );
};

export default OrderDetails;
