import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Nav from "../components/Nav";
import ShinyDiv from "../components/ShinyDiv";
import UnpaidOrderAlert from "../components/UnpaidOrderAlert";

function HomeLayout() {
  const message =
    "السلام عليكم، ودي أسأل عن أنواع اللحم اللي عندكم. ممكن تعطوني زيادة معلومات؟ يعطيكم العافية 😄🍖";
  return (
    <div className="wrapper">
      <UnpaidOrderAlert />

      <Nav />
      <div className="content">
        <Outlet />
      </div>

      <Footer />

      {/* whatsapp  */}
      <a
        href={`https://api.whatsapp.com/send?phone=966591144449&text=${message}`}
        target="_blank"
        className="fixed bottom-24 left-3 w-[50px] h-[50px]"
      >
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" />
      </a>
      {/* <WhatsAppButton /> */}
    </div>
  );
}

const WhatsAppButton = () => {
  return (
    <div className="fixed bottom-20 left-4 bg-green-500 text-white rounded-full p-3 shadow-lg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
      </svg>
    </div>
  );
};

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    // Check on initial load
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    !isMobile && (
      <div className="bg-black w-full h-80 relative p-3 ">
        <div className="container py-4">
          <div className=" row justify-between flex">
            <div className="col-md-4">
              <h1 className="font-semibold ">بيانات التواصل</h1>

              <div className="space-y-4 mt-4">
                <div className="row">
                  <img className="mx-2 w-6 h-6" src="/icons/phone.png" />
                  <p>+96612352617</p>
                </div>
                <div className="row">
                  <img className="mx-2 w-6 h-6" src="/icons/mail.png" />
                  <p>info@COHOOLMEAT.com</p>
                </div>
                <div className="row">
                  <img className="mx-2 w-6 h-6" src="/icons/location.png" />
                  <p>العنوان الرياض شارع الجزار</p>
                </div>
              </div>
              <hr />
            </div>
            <div className="col-md-4 justify-center ">
              <div className="justify-center items-center flex-col flex p-2">
                <img src="/imges/logo.png" />
                <p className="text-center">
                  افضل انواع الحوم المتواجده و جميع انواع اللحوم التي تحتاجة مثل
                  لحم الغذال و البقر و الجمال
                </p>
              </div>
            </div>
            <div className="col" />
            <div className="col-md-2 ">
              <h1 className="font-semibold ">صفحات سهول</h1>
              <div className="space-y-2 right">
                <ul>
                  <Link to={"/orders"}>
                    {" "}
                    <p className="text-opacity-70 text-gray-500 font-medium">
                      الطلبات
                    </p>
                  </Link>
                  <Link to={"/about"}>
                    {" "}
                    <p className="text-opacity-70 text-gray-500 font-medium">
                      عن سهول
                    </p>
                  </Link>
                  <Link to={"/contact-us"}>
                    {" "}
                    <p className="text-opacity-70 text-gray-500 font-medium">
                      تواصل معنا
                    </p>
                  </Link>
                  <Link to={"/privacy"}>
                    {" "}
                    <p className="text-opacity-70 text-gray-500 font-medium">
                      سياسة الخصوصية
                    </p>
                  </Link>
                  <Link to={"/policy"}>
                    {" "}
                    <p className="text-opacity-70 text-gray-500 font-medium">
                      الشروط و الأحكام{" "}
                    </p>
                  </Link>
                </ul>
              </div>
              <hr />
            </div>
          </div>

          <div className="row justify-between border-t pt-2 mt-3 border-t-slate-500">
            <p>2023 All Rights Reserved</p>
            <p>2023 All Rights Reserved</p>
          </div>
        </div>
      </div>
    )
  );
};
export default HomeLayout;
