import React, { useContext, useEffect, useState } from "react";
import {
  Bell,
  Menu,
  Trash2,
  MapPin,
  ShoppingBag,
  ChevronDown,
  Plus,
  Minus,
  Video,
  Grid,
  Home,
  User,
  PocketKnife,
  Info,
  InfoIcon,
  Grip,
  Truck,
  Calendar,
  CalendarDays,
  Clock,
  SunMoon,
  Sun,
} from "lucide-react";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import Switch from "../../../components/Switch";
import DatePicker from "../../../components/mobile/DatePicker";
import BottomSheet from "../../../components/mobile/BottomSheet";
import Button from "../../../components/Button";
import GeneralServices from "../../../services/GeneralServices";
import EmptyCart from "./EmptyCart";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import ShinyDiv from "../../../components/ShinyDiv";
import {
  calculateDiscountAmount,
  calculateTotals,
  resetCard,
} from "../../../utils/utility";
import Input from "../../../components/Input";
import CardInfo from "../components/CardInfo";
import BankTransferPayment from "../components/BankTransferPayment";

function ShoppingCardMobile() {
  const [cuttingTypes, setCuttingTypes] = useState([]);

  const { card, setCard } = useContext(AuthContext);

  const [validation, setValidation] = useState([]);

  const service = new GeneralServices();

  const fetchCuttingTypes = async () => {
    try {
      const res = await service.get("cutting/types");
      setCuttingTypes(res);
    } catch (error) {}
  };
  useEffect(() => {
    fetchCuttingTypes();
  }, []);

  // useEffect(() => {
  //     let productsTotal = card.products.reduce((acc, product) => acc + product.price * product.quantity, 0);
  //     let optionsTotal = card.options.reduce((acc, option) => acc + option.price, 0);
  //     setCard({ ...card, total: productsTotal, optionsTotal: optionsTotal })

  // }, [card.products, card.options])

  useEffect(() => {
    const {
      productTotal,
      optionsTotal,
      total,
      discountAmountRounded,
      tax,
      grandTotal,
    } = calculateTotals(
      card.products,
      card.options,
      card.discountAmount,
      card.deliveryType,
      card.coupon
    );
    setCard((prevCart) => ({
      ...prevCart,
      productTotal: productTotal,
      optionsTotal: optionsTotal,
      total: total,
      tax: tax,
      discountAmount: discountAmountRounded,
      grandTotal: grandTotal,
    }));
  }, [card.products, card.options, card.copon, card.deliveryType]);

  if (card.products.length === 0) return <EmptyCart />;
  return (
    <div className="mx-2 mb-[120px]">
      <CartItems validation={validation} cuttingTypes={cuttingTypes} />
      <Options />
      <Coupon />
      <Discount />
      <DeliveryOptions validation={validation?.deliveryType} />
      <DeliveryDate />
      <PaymentMethods
        card={card}
        setCard={setCard}
        validation={validation?.paymentMethod}
      />
      <CartSummary cuttingTypes={cuttingTypes} setValidation={setValidation} />
      <div className="h-[80px]"></div>
    </div>
  );
}

const CartItems = ({ cuttingTypes, validation }) => {
  const { card, setCard } = useContext(AuthContext);

  return (
    <div>
      {card.products &&
        card.products.map((product, index) => (
          <CartItem
            validation={validation["products." + index + ".cuttingType"]}
            key={product.id}
            {...product}
            product={product}
            cuttingTypes={cuttingTypes}
            card={card}
            setCard={setCard}
          />
        ))}
    </div>
  );
};

const CartItem = ({
  name,
  price,
  quantity,
  image,
  product,
  cuttingTypes,
  card,
  setCard,
  validation,
}) => {
  const handleIncres = () => {
    const updatedProducts = card.products.map((item) =>
      item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    );

    setCard({ ...card, products: updatedProducts });
  };

  const handleDecrement = () => {
    const updatedProducts = card.products
      .map((item) =>
        item.id === product.id
          ? { ...item, quantity: Math.max(0, item.quantity - 1) }
          : item
      )
      .filter((item) => item.quantity > 0);

    setCard({ ...card, products: updatedProducts });
  };

  const handleDeleteFromCard = () => {
    const productIdToRemove = product.id;
    const updatedProducts = card.products.filter(
      (product) => product.id !== productIdToRemove
    );
    setCard({ ...card, products: updatedProducts });
  };

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const handleCuttingTypes = (cuttingTypeId) => {
    const updatedProducts = card.products.map((item) =>
      item.id === product.id ? { ...item, cuttingType: cuttingTypeId } : item
    );

    setCard({ ...card, products: updatedProducts });
    onClose();
  };

  return (
    <>
      {isOpen && (
        <BottomSheet
          title="اختر طريقة التقطيع"
          isOpen={isOpen}
          onClose={onClose}
        >
          {cuttingTypes.map((cuttingType) => (
            <div
              onClick={() => handleCuttingTypes(cuttingType)}
              className="flex gap-1 mb-3 items-center justify-between"
              key={cuttingType.id}
            >
              <div className="flex gap-1 select-none">
                <p>{cuttingType.name}</p>
              </div>
              <RadioButton
                checked={product?.cuttingType?.id === cuttingType.id}
              />
            </div>
          ))}
        </BottomSheet>
      )}
      <div className="mt-1 shadow-sm  bg-white overflow-hidden rounded-2xl">
        <div className="flex w-full justify-between items-center h-full">
          <div className="flex justify-between items-center h-full w-full  gap-2">
            <img
              src={image}
              alt={name}
              className="w-24 h-24 object-cover rounded-lb-md "
            />
            <div className="flex flex-col justify-around items-start  w-full b  h-24">
              <h1>{name}</h1>
              <h1>{price} ريال</h1>
              <div className="flex justify-between  items-center w-full">
                <div className="flex items-center text-center group overflow-hidden rounded-lg border text-[18px]">
                  <div className="w-[40px] flex items-center px-2  justify-center  bg-[#E8E7E7] ">
                    <span
                      onClick={handleIncres}
                      className="text-[#5C6678] font-semibold cursor-pointer"
                    >
                      +
                    </span>
                  </div>
                  <div className="bg-[#F5F5F5]">
                    <p className="mx-2 ">{quantity}</p>
                  </div>
                  <div className="w-[40px] flex items-center justify-center  px-2  bg-[#E8E7E7] ">
                    <span
                      onClick={handleDecrement}
                      className="text-[#5C6678] font-semibold cursor-pointer"
                    >
                      -
                    </span>
                  </div>
                </div>
                <div className="mx-1">
                  <p className="text-[#5C6678] font-medium">
                    <span className="text-[#5C6678] font-semibold">
                      {price * quantity} ريال
                    </span>
                    <span>{quantity}x</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div onClick={handleDeleteFromCard} className="bg-red-500  h-24 ">
            <Trash2 size={24} className="text-white m-1 h-full" />
          </div>
        </div>
        <div
          onClick={() => setIsOpen(true)}
          className="flex justify-between items-center px-2 py-3"
        >
          <span
            className="text-gray-600"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            طريقة التقطيع
          </span>
          <div className="flex  w-full items-center justify-between mx-2">
            <div className="flex gap-2">
              <PocketKnife size={16} className="text-gray-600" />

              {product?.cuttingType ? (
                product?.cuttingType?.name
              ) : (
                <p className="text-gray-300">اختار طريقة التقطيع</p>
              )}
            </div>
            <ChevronDown size={16} className="text-gray-600" />
          </div>
        </div>
        {/* <div className='flex items-center justify-center bg-red-500'>
                <p className='text-white text-lg'>{validation}</p>
            </div> */}
        {validation && <ShinyDiv validation={validation} />}
      </div>
    </>
  );
};

// خيارات
const Options = () => {
  const [options, setOptions] = useState([]);
  const { card, setCard } = useContext(AuthContext);

  const service = new GeneralServices();

  const fetchOptions = async () => {
    try {
      const res = await service.get("additional/options");
      setOptions(res);
    } catch (error) {}
  };

  const handleToggleOptions = (item) => {
    const isOptionExist =
      card.options && card.options.some((option) => option.id === item.id);

    if (isOptionExist) {
      const updatedOptions = card.options.filter(
        (option) => option.id !== item.id
      );
      setCard((prevCard) => ({ ...prevCard, options: updatedOptions }));
    } else {
      const updatedCard = {
        ...card,
        options: card.options ? [...card.options, item] : [item],
      };
      setCard(updatedCard);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div className="p-4 text-[#5C6678] group rounded-lg border-[0.4px] mt-2">
      <div className="flex gap-1 mb-3 ">
        <Grip size={16} className="text-[#5C6678]" />
        <h1 className="text-[#5C6678] text-[18px] font-semibold">الاضافات</h1>
      </div>

      {options &&
        options.length > 0 &&
        options.map((item) => (
          <div
            key={item.id}
            className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 "
          >
            <p>
              {item?.name}
              {item?.price > 0 && (
                <span className="text-rose-400 font-medium">
                  {" "}
                  {item?.price} ريال
                </span>
              )}
            </p>
            <Switch
              key={item.id}
              checked={
                card.options &&
                card.options.some((option) => option.id === item.id)
                  ? true
                  : false
              }
              onChange={() => handleToggleOptions(item)}
            />
          </div>
        ))}
    </div>
  );
};
// كوبون
const Coupon = () => {
  const { card, setCard } = useContext(AuthContext);
  const [copon, setCopon] = useState({
    code: null,
    status: null,
    message: null,
  });

  const service = new GeneralServices();

  const handleCoupon = async () => {
    setCopon({
      ...copon,
      status: null,
      message: null,
    });

    setCard({
      ...card,
      coupon: null,
      discountAmount: 0,
    });
    try {
      const foundCoupon = await service.post("coupon", { code: copon.code });

      setCopon({
        ...copon,
        code: foundCoupon.code,
        status: true,
      });

      const discountAmount = calculateDiscountAmount(
        card.productTotal,
        foundCoupon
      );
      // Update cart state
      setCard({
        ...card,
        coupon: foundCoupon,
        discountAmount: discountAmount,
      });
    } catch (error) {
      if (error.state == 400) {
        showErrorToast(error.message);
        setCopon({
          ...copon,
          message: error.message,
        });
      } else if (error.state == 422) {
        setCopon({
          ...copon,
          message: error.validationData?.code?.[0] ?? null,
          status: false,
        });
      }
    } finally {
    }
  };

  return (
    <div className="p-4 text-[#5C6678] group rounded-lg border-dashed border-[1.8px] mt-2">
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
        <div>
          <input
            onChange={(e) => setCopon({ ...copon, code: e.target.value })}
            value={copon.code}
            placeholder="ادخل كود الخصم"
            className={`w-full h-10 rounded-lg px-4  ${
              copon.status == false &&
              "border-red-500 border-[1.5px] text-red-600 focus:outline-none focus:ring-1 focus:ring-red-300"
            } 
                        
                        ${
                          copon.status == true &&
                          "border-green-500 border-[1.5px] text-green-600 focus:outline-none focus:ring-1 focus:ring-green-300"
                        }
                        
                        ${
                          copon.status == null &&
                          "border border-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                        }
                        `}
          />
        </div>
        <div className="w-4" />
        <div
          onClick={handleCoupon}
          className="cursor-pointer text-center flex-grow  bg-[#5C6678] h-10 text-white border-dashed py-1 rounded-lg flex justify-center items-center"
        >
          <p className="px-2">تطبيق</p>
        </div>
      </div>
      <p className="text-sm text-red-500">{copon.message}</p>
    </div>
  );
};

// خصم خاص بالجمعيات الخيرية
const Discount = () => {
  return <></>;
  return (
    <div className="p-4 text-[#5C6678] group  rounded-lg border-[0.4px] mt-2">
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
        <span className="text-[#5C6678] text-[18px] font-semibold">
          خصم خاص بالجمعيات الخيرية
        </span>

        <Switch
          checked={false}
          onChange={() => {}}
          className="bg-[#5C6678]  relative inline-flex h-6 w-11 items-center rounded-full"
        />
      </div>
      <div>
        <InfoIcon size={16} className="text-[#FFC048]" />
        <p>
          سوف يتم توصيل هذا الطلب بمعرفتنا الى (جمعية البر الخيرية) وستصلك رسالة
          من الجمعية بتأكيد استلام الطلب بعد التوصيل
        </p>
      </div>
    </div>
  );
};

const PaymentMethods = ({ card, setCard, validation }) => {
  const [paymentOptionsOptions, setPaymentOptionsOptions] = useState([]);

  const service = new GeneralServices();

  const [isOpen, setIsOpen] = useState(false);

  const fetchPaymentOptions = async () => {
    try {
      const res = await service.get("payment/methods");
      setPaymentOptionsOptions(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  // اختيار طريقة الدفع
  const handleChangePayment = (data) => {
    if (data.id == 2) setIsOpen(true);
    setCard({ ...card, paymentMethod: data.id });
  };

  const onClose = () => setIsOpen(false);
  useEffect(() => {
    fetchPaymentOptions();
  }, []);

  return (
    <div
      className={`p-4 ${
        card.paymentMethod ? "block" : "hiddenm"
      } text-[#5C6678] group rounded-lg border-[0.4px] mt-2`}
    >
      <BottomSheet isOpen={isOpen} onClose={onClose} title="طرق الدفع">
        <CardInfo
          card={card}
          setCard={setCard}
          validation={validation}
          onClose={onClose}
        />
      </BottomSheet>
      <div className="flex gap-1 mb-3 items-center ">
        <Grip size={16} className="text-[#5C6678]" />
        <h1 className="text-[#5C6678] text-[18px] font-semibold">طرق الدفع</h1>
      </div>
      {paymentOptionsOptions.map((method) => {
        if (method.id === 1 && card.deliveryType && card.deliveryType.id == 6)
          return;
        if (method.id === 1 && card.deliveryType && card.deliveryType.id == 5)
          return;
        return (
          <div
            key={method.name}
            onClick={() => handleChangePayment(method)}
            className="flex select-none cursor-pointer gap-2 items-center mb-2 border-b-1 border-b py-1 border-stone-100"
          >
            <RadioButton checked={method.id === card.paymentMethod} />
            {method.icon && (
              <img src={method.icon} alt={method.name} className=" h-5" />
            )}
            <span>{method.name}</span>
          </div>
        );
      })}
      <small className="text-red-400">
        {card.paymentMethod ? "" : "يرجى تحديد طريقة الدفع"}
      </small>

      {validation && <ShinyDiv validation={validation} />}
    </div>
  );
};

const CardInfos = ({ card, setCard, validation, onClose }) => {
  return (
    <>
      <Input
        value={card?.source?.name}
        onChange={(e) =>
          setCard({
            ...card,
            source: { ...card.source, name: e.target.value },
          })
        }
        placeholder={"اسم حامل البطاقه"}
      />
      <Input
        value={card?.source?.number}
        onChange={(e) =>
          setCard({
            ...card,
            source: { ...card.source, number: e.target.value },
          })
        }
        placeholder={"اكتب رقم البطاقة"}
      />
      <div className="flex justify-between">
        <div className="w-[29%]">
          <Input
            value={card?.source?.cvc}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, cvc: e.target.value },
              })
            }
            placeholder={"cvc"}
          />
        </div>
        <div className="w-[29%]">
          <Input
            value={card?.source?.month}
            placeholder={"الشهر"}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, month: e.target.value },
              })
            }
          />
        </div>
        <div className="w-[29%]">
          <Input
            value={card?.source?.year}
            onChange={(e) =>
              setCard({
                ...card,
                source: { ...card.source, year: e.target.value },
              })
            }
            type="number"
            placeholder={"السنه"}
          />
        </div>
      </div>
      <Button onClick={onClose} text={"تأكيد"} />
    </>
  );
};
// خيارات التوصيل
const DeliveryOptions = ({ validation }) => {
  const { card, setCard } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);

  const service = new GeneralServices();

  const [deliveryTypes, setDeliveryTypes] = useState([]);

  const fetchDeliveryTypes = async () => {
    try {
      const res = await service.get("delivery/types");
      setDeliveryTypes(res);
    } catch (error) {}
  };

  const handleChangeDelivery = (type) => {
    let updatedCard = { ...card, deliveryType: type };

    if (type.id === 6 && card?.paymentMethod == 1) {
      updatedCard.paymentMethod = null;
    }
    setCard(updatedCard);
  };

  useEffect(() => {
    fetchDeliveryTypes();
  }, []);

  useEffect(() => {
    console.log("card", card);
  }, [card.deliveryType]);

  return (
    <>
      <div className="p-4 text-[#5C6678] group rounded-lg border-[0.4px] mt-2">
        <div className="flex gap-1 mb-3 items-center ">
          <Truck size={24} className="text-[#5C6678]" />
          <h1 className="text-[#5C6678] text-[18px] font-semibold">
            خيارات التوصيل
          </h1>
        </div>
        {deliveryTypes.map((type, index) => (
          <div
            key={type.id}
            onClick={() => handleChangeDelivery(type)}
            className="flex select-none cursor-pointer justify-between gap-1 items-center mb-2 border-b-1 border-b py-1 border-stone-100"
          >
            <div className="flex gap-1 items-center">
              <RadioButton checked={card?.deliveryType?.id === type.id} />

              <p>{type.name}</p>
            </div>
            <p
              className={
                type.price == 0
                  ? "text-green-500 font-semibold"
                  : `text-rose-500 font-light`
              }
            >
              {type.price == 0 ? "مجاني" : `${type.price} ريال`}
            </p>
          </div>
        ))}

        {validation && <ShinyDiv validation={validation} />}
      </div>
    </>
  );
};

// تاريخ التوصيل
const DeliveryDate = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const { card, setCard } = useContext(AuthContext);

  // const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const handleChangePayment = (data) => {
    console.log("payment", data);
  };

  useEffect(() => {
    if (selectedDate) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = selectedDate.getDate().toString().padStart(2, "0");

      const updatedCard = { ...card, deliveryDate: `${year}-${month}-${day}` };
      setCard(updatedCard);
    } else {
      const updatedCard = { ...card, deliveryDate: null };
      setCard(updatedCard);
    }
  }, [selectedDate]);

  if (!card?.deliveryType || card?.deliveryType.id !== 5) return <></>;
  return (
    <>
      {/* <BottomSheet title='أختر وقت التوصيل' isOpen={isOpen} onClose={onClose} >
            <div className='flex gap-1 mb-3 items-center justify-between'>
                <div className='flex gap-1 select-none'>
                    <Sun size={24} className="text-orange-600" />
                    <p>الفترة الصباحية من 10 صباحاً - 1 ظهراً</p>
                </div>
                <RadioButton checked={false} />
            </div>
            <div className='flex gap-1 mb-3 items-center justify-between'>
                <div className='flex gap-1 select-none'>
                    <SunMoon size={24} className="text-[#5C6678]" />
                    <p>الفترة المسائية من 3 عصراً - 10 مساءً</p>
                </div>
                <RadioButton checked={false} />
            </div>

            <Button bg='rose-500' text='تأكيد' onClick={() => setIsOpen(false)} />
        </BottomSheet> */}
      <div className="p-4 text-[#5C6678] group rounded-lg border-[0.4px] mt-2">
        {/* <div className="flex gap-1 mb-3 items-center ">
          <CalendarDays size={24} className="text-[#5C6678]" />
          <h1 className="text-[#5C6678] text-[18px] font-semibold">
            حدد اليوم
          </h1>
        </div> */}

        <div className="flex select-none justify-between cursor-pointer gap-1 items-center mb-2 ">
          <DatePicker onDateSelect={setSelectedDate} />

          {/* <div onClick={() => setIsOpen(true)} className='border-[1px] p-2 w-1/2 flex items-center gap-1 rounded-lg'>
                    <Clock size={24} className="text-[#5C6678]" />
                    <p>وقت التوصيل</p>
                </div> */}
        </div>
      </div>
    </>
  );
};

// عنوان التوصيل

const RadioButton = ({ checked = false, onClick = null }) => (
  <div
    onClick={onClick}
    className={`rounded-full cursor-pointer  ${
      !checked ? " border-[#818B9D]" : "border-rose-500"
    } border-[1px] w-[24px] h-[24px] flex items-center justify-center`}
  >
    <div
      className={`w-[18px] h-[18px] rounded-full ${
        !checked ? " bg-[#818B9D]" : "bg-rose-500"
      }`}
    />
  </div>
);

const CartSummary = ({ cuttingTypes, setValidation }) => {
  const { card, setCard } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const service = new GeneralServices();

  const dispatchOrder = async () => {
    setValidation([]);
    setIsLoading(true);

    try {
      const res = await service.post("shopping/cart", card);

      if (isPaymentMethodFour(res.order)) {
        handlePaymentMethodFourResponse(res.order);
      } else if (
        res.order.payment_method_id === 2 ||
        res.order.payment_method_id === 6
      ) {
        handleSuccessfulPayment(res.order.id, res.transaction_url);
      } else {
        setCard(resetCard());
        showSuccessToast(res.massage || "تم الطلب بنجاح");
        navigate("/payment-success/" + res.order.id);
      }

      // setCard(resetCard());

      showSuccessToast("تم الطلب بنجاح");
    } catch (error) {
      console.log("error", error);
      if (error.validationData) {
        setValidation(error.validationData);
        validateCardDetails(error);

        const validationData = error.validationData;

        if (validationData?.deliveryType) {
          showErrorToast(validationData.deliveryType?.[0]);
        } else if (validationData?.paymentMethod) {
          showErrorToast(validationData.paymentMethod?.[0]);
        } else if (validationData?.deliveryDate) {
          showErrorToast(validationData.deliveryDate?.[0]);
        } else if (validationData["products.0.cuttingType"]) {
          showErrorToast(validationData?.["products.0.cuttingType"][0]);
        } else {
          showErrorToast("يوجد خطاء");
        }
      }

      if (error.state == 401) {
        showErrorToast("يجب عليك تسجيل الدخول");
      } else if (error.state == 400) {
        // showErrorToast(error.message);
      } else if (error.state == 422) {
        // showErrorToast(error.message);
      } else {
        if (error.message != "Validation failed") {
          showErrorToast(error.message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  function validateCardDetails(error) {
    if (error.validationData?.source) {
      const { source } = error.validationData;

      for (const [field, message] of Object.entries(cardFieldErrors)) {
        if (source[field]) {
          showErrorToast(`${message}: ${source[field]}`);
          return;
        }
      }
    }

    // If we get here, there was an error, but not one we specifically handle
    // showErrorToast(
    //   "An error occurred while processing your card. Please try again."
    // );
  }

  const isPaymentMethodFour = (order) => {
    return order?.payment_method_id === 4;
  };

  const handlePaymentMethodFourResponse = (order) => {
    const paymentData = order.payment_data;
    if (paymentData) {
      if (paymentData.status === "rejected") {
        handleRejectedPayment(paymentData);
      } else {
        const webUrl = findWebUrl(paymentData);
        if (webUrl) {
          handleSuccessfulPayment(order.id, webUrl);
        } else {
          showWarnings(paymentData.warnings);
        }
      }
    } else {
      showErrorToast("حدث خطأ في معالجة الدفع");
    }
  };

  const findWebUrl = (paymentData) => {
    if (
      paymentData.configuration &&
      paymentData.configuration.available_products &&
      paymentData.configuration.available_products.installments &&
      paymentData.configuration.available_products.installments.length > 0
    ) {
      return paymentData.configuration.available_products.installments[0]
        .web_url;
    }
    return null;
  };

  const handleSuccessfulPayment = (orderId, webUrl) => {
    localStorage.setItem("unpaidOrder", orderId);
    window.open(webUrl, "_blank");
  };

  const handleRejectedPayment = (paymentData) => {
    const rejectionReason =
      paymentData.rejection_reason_code || "unknown_reason";
    const rejectionMessages = {
      not_enough_limit:
        "عذراً، ما عندك حد ائتماني كافي لإتمام هالشراء. ياليت تختار طريقة دفع ثانية أو تقلل مبلغ الطلب. شكراً لتفهمك!",
      order_amount_too_high:
        "المبلغ المطلوب في الطلب مرتفع جداً. ياليت تقلل مبلغ الطلب أو تختار طريقة دفع ثانية. شكراً لتفهمك!",
      // Add more rejection reasons as needed
      unknown_reason:
        "حدث خطأ غير معروف. ياليت تحاول مرة ثانية أو تتواصل مع الدعم. شكراً لتفهمك!",
    };
    showErrorToast(rejectionMessages[rejectionReason]);
  };

  const showWarnings = (warnings) => {
    if (warnings && warnings.length > 0) {
      const warningMessages = warnings.map((warning) => {
        if (warning.name === "phone") {
          return "الرجاء إدخال رقم الهاتف";
        } else if (warning.name === "email") {
          return "الرجاء إدخال البريد الإلكتروني";
        }
        return warning.message || "تحذير غير معروف";
      });
      showWarningToast(warningMessages.join(". "));
    } else {
      showWarningToast("هناك بعض المشاكل في معلومات الطلب");
    }
  };

  return (
    <div className="p-4 text-[#5C6678] group rounded-lg border-[0.4px] mt-2">
      <table className="w-full">
        <tbody>
          {card.products &&
            card.products.map((product) => (
              <tr className="flex justify-between  items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
                <td className="w-[40%] ">{product.name}</td>
                <td className="w-[6%] gap-3 ">
                  <p className="mx-2">x{product.quantity}</p>
                </td>
                <td className="w-[30%]">
                  {(
                    <span className="text-teal-500 font-light">
                      {product?.cuttingType?.name}
                    </span>
                  ) || <span className="text-red-400">لم يتم الاختيار</span>}
                </td>
                <td className="text-[#5C6678] w-[13%] font-semibold">
                  {product.quantity * product.price}
                  <span className="mx-1">ريال</span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="mb-4">
        <textarea
          placeholder="اكتب تعليقك"
          value={card.note}
          onChange={(e) => setCard({ ...card, note: e.target.value })}
          className="w-full h-20 rounded-lg border-[0.4px] border-gray-300 px-4 text-gray-600 focus:outline-none focus:ring-[0.4px] focus:ring-gray-300 "
        />
      </div>

      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 mt-3 border-stone-100">
        <span>المنتجات </span>
        <span>{card.productTotal} ريال</span>
      </div>

      {card.optionsTotal > 0 && (
        <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 mt-3 border-stone-100">
          <span>الاضافات </span>
          <span>
            {card.optionsTotal}
            <span className="mx-1">ريال</span>
          </span>
        </div>
      )}
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 mt-3 border-stone-100">
        <span>الشحن </span>
        <span>{card?.deliveryType?.price ?? 0} ريال</span>
      </div>
      {card.discountAmount > 0 && (
        <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 mt-3 border-stone-100">
          <span>الخصومات </span>
          <span className=" border-[1px] border-dashed text-green-700  text-md p-1 rounded-md border-gray-400">
            {card.discountAmount} ريال
          </span>
        </div>
      )}
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 mt-3 border-stone-100">
        <span>ضريبه القيمه المضافه 15%</span>
        <span>{card.tax} ريال</span>
      </div>
      {/* <div className="flex justify-between items-center mb-4">
                <span>خصم الجمعيات الخيرية</span>
                <span>0 ريال</span>
            </div> */}
      <div className="flex justify-between items-center text-green-500 font-bold mb-4">
        <span>المجموع</span>
        <span>{card.grandTotal} ريال</span>
      </div>
      <BankTransferPayment card={card} />
      <Button
        onClick={dispatchOrder}
        text={"تأكيد الطلب"}
        loading={isLoading}
      />
    </div>
  );
};

export default ShoppingCardMobile;
